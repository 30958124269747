import React from "react";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import Layout from "./layout/Layout";
import Stocks from "./pages/Stocks";
import Indexes from "./pages/Indexes";
import Forex from "./pages/Forex";
import Disclaimer from "./pages/Disclaimer";
// import Commodities from "./pages/Commodities";
// import Macro from "./pages/Macro";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Layout />,
		children: [
			{ index: true, element: <Navigate to="/stocks" /> },
			{ path: "stocks", element: <Stocks /> },
			{ path: "indexes", element: <Indexes /> },
			{ path: "forex", element: <Forex /> },
			{ path: "disclaimer", element: <Disclaimer /> },
			// { path: "commodities", element: <Commodities /> },
			// { path: "macro", element: <Macro /> },
		],
	},
]);

const App = () => {
	return <RouterProvider router={router} />;
};

export default App;

import React, { useEffect, useState } from "react";
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { getFlagSource } from "../data";
import { Wrapper } from "../css/MiddleColumnListingStyle";

const DisplaySearchList = ({
	selectedStocks,
	selectedStock,
	handleTickerSelection,
	page,
	maxPage,
	increasePage,
	decreasePage,
	goToMaxPage,
	goToMinPage,
	selectedRegion,
	selectedCountry,
	selectedMarket,
}) => {
	const [localStocksList, setLocalStocksList] = useState(selectedStocks.data);
	const { mode } = selectedStocks;

	const getStockTrunk = (page) => {
		const n1 = page * 100;
		const n2 = (page + 1) * 100;
		return selectedStocks.data.filter((stock) => stock.country).slice(n1, n2);
	};

	const getClasses = (stock) => {
		let classes = "stock-line";
		// console.log(stock);
		// console.log(selectedStock);
		if (selectedStock) {
			if (mode === "country-market-mic") {
				if (stock.name === selectedStock.name) {
					classes += " active";
				}
			} else if (mode === "search-term") {
				if (stock.instrument_name === selectedStock.instrument_name) {
					classes += " active";
				}
			}
		}

		return classes;
	};

	useEffect(() => {
		setLocalStocksList(getStockTrunk(page));
	}, [page, selectedStocks]);

	return (
		<Wrapper>
			{mode === "country-market-mic" && (
				<div className="breadCrumbs">
					<span>{selectedRegion}</span>
					{selectedCountry ? <span>&gt;&gt;{selectedCountry}</span> : null}
					{selectedMarket ? <span>&gt;&gt;{selectedMarket}</span> : null}
				</div>
			)}
			{mode === "search-term" && (
				<div className="breadCrumbs">
					<span>Search results :</span>
				</div>
			)}
			<div className="stock-line noHover">
				<span className="span-header">Country</span>
				<span className="span-header">Name</span>
				<span className="span-header">Currency</span>
				<span className="span-header">Echange</span>
				<span className="span-header">mic</span>
			</div>
			{localStocksList.length === 0 && <div className="stock-line">No results</div>}
			{localStocksList?.map((stock) => (
				<div className={getClasses(stock)} onClick={() => handleTickerSelection(stock)}>
					{stock.country ? (
						<span>
							<img src={getFlagSource(stock.country)} alt="" className="flag" />
						</span>
					) : (
						<span>Unknown</span>
					)}
					<span>{stock.instrument_name || stock.name}</span>
					<span>{stock.currency || "unknown"}</span>
					<span>{stock.exchange || "unknown"}</span>
					<span>{stock.mic_code || "unknown"}</span>
				</div>
			))}
			{maxPage > 1 && (
				<div className="pagination-container">
					<button className="btn-container" onClick={goToMinPage}>
						<FiChevronsLeft />
					</button>
					<button onClick={decreasePage} className="btn-container">
						<FiChevronLeft />
					</button>
					<p>
						{page + 1} / {maxPage}
					</p>
					<button onClick={increasePage} className="btn-container">
						<FiChevronRight />
					</button>
					<button className="btn-container" onClick={goToMaxPage}>
						<FiChevronsRight />
					</button>
				</div>
			)}
		</Wrapper>
	);
};

export default DisplaySearchList;

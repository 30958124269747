import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.section`
	width: 100%;
	/* padding: 0 1rem; */
	display: grid;
	/* grid-template-columns: 1fr 1fr 1fr 1fr 1fr; */
	grid-template-columns: 1fr 1fr 1fr 1fr;
	border-bottom: 1px solid var(--blue-2);

	a {
		color: inherit;
		transition: all 0.5s ease-in-out;
		transition: color 2s ease-out;
		display: flex;
		place-content: center;
		/* border-bottom: 1px solid var(--blue-2); */
	}
	a:hover {
		background: radial-gradient(circle, var(--back-5) 0%, var(--blue-7) 100%);
		outline: 1px solid var(--blue-2);
		border-top-right-radius: 15px;
		border-top-left-radius: 15px;
		color: var(--font-3);
	}
	.pending {
	}
	.active {
		background: none !important;
		background-color: var(--font-5) !important;
		color: var(--back-5) !important;
		border: 1px solid var(--blue-2);
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
	}
	.transitioning {
	}
	@media screen and (max-width: 800px) {
		font-size: 3rem;
	}
	@media screen and (max-width: 500px) {
		font-size: 2.5rem;
	}
	@media screen and (max-width: 400px) {
		font-size: 2rem;
	}
`;

const Navbar = () => {
	return (
		<Wrapper>
			<NavLink to="/stocks">stocks</NavLink>
			<NavLink to="/indexes">indexes</NavLink>
			<NavLink to="/forex">forex</NavLink>
			{/* <NavLink to="/commodities">commodities</NavLink>
			<NavLink to="/macro">macro economics</NavLink> */}
		</Wrapper>
	);
};

export default Navbar;

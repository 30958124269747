import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";

const notify = () => toast("API limits reached", { autoClose: 60000, closeOnClick: true });

const StyledToast = styled(ToastContainer)`
	.Toastify__toast {
		background-color: var(--font-5);
	}
	.Toastify__toast-body {
		color: black;
	}
	.Toastify__progress-bar {
		background-color: blue;
	}
`;

export { StyledToast, notify };

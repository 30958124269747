import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Stockchart from "highcharts/modules/stock";
import { timestampFormatter } from "../data";
import styled from "styled-components";

const Wrapper = styled.div`
	position: relative;
	.btns-container {
		display: flex;
		gap: 0.5rem;
		.btn {
			background-color: var(--font-5);
			border: none;
			outline: none;
			padding: 0.5rem;
			cursor: pointer;
			&:hover {
				background-color: var(--font-2);
			}
		}
		.active {
			background-color: var(--font-2);
		}
	}
`;

Stockchart(Highcharts);

const ValuesChart = ({ values, setTimeslot }) => {
	const [tsActiveBtn, setTsActiveBtn] = useState("1month");

	const valuesFormatter = () => {
		let data = [];
		data = values.map((obj) => [timestampFormatter(obj.datetime), parseFloat(obj.open), parseFloat(obj.high), parseFloat(obj.low), parseFloat(obj.close)]).reverse();
		return data;
	};

	const valuesTransformed = valuesFormatter(values);

	const options = {
		plotOptions: {
			candlestick: {
				color: "pink",
				lineColor: "red",
				upColor: "lightgreen",
				upLineColor: "green",
			},
		},
		rangeSelector: {
			selected: 1,
		},
		legend: {
			enabled: false,
		},
		series: [
			{
				type: "candlestick",
				data: valuesTransformed,
			},
		],
		title: {
			text: null,
		},
		xAxis: {
			title: {
				enabled: false,
			},
			type: "datetime",
			dateTimeLabelFormats: {
				day: {
					main: "%e %b",
				},
				month: {
					main: "%b '%y",
				},
			},
			tickInterval: 0.1,
			lineColor: "#e45116f6",
			gridLineColor: "#e45116f6",
			tickColor: "#e45116f6",
			labels: {
				style: {
					color: "#e45116f6",
				},
			},
		},
		yAxis: {
			opposite: true,
			title: {
				enabled: false,
			},
			labels: {
				style: {
					color: "#e45116f6",
				},
			},
			gridLineColor: "#e45116f6",

			// title: null,
		},
		chart: {
			backgroundColor: "rgb(10, 15, 20)",
		},
		tooltip: {
			style: {
				fontSize: "1.6rem",
			},
		},
	};

	const handleTimeslotSelection = (evt) => {
		const ts = evt.target.dataset.timeslot;
		setTsActiveBtn(ts);
		const now = new Date();
		const nowYear = now.getFullYear();
		const nowMonth = now.getMonth() + 1;
		const nowDate = now.getDate();
		const end_date = `${nowYear}-${nowMonth}-${nowDate}`;
		let start_date = "";
		switch (ts) {
			case "1month": {
				if (nowMonth === 1) {
					start_date = `${nowYear - 1}-12-${nowDate}`;
				} else {
					start_date = `${nowYear}-${nowMonth - 1}-${nowDate}`;
				}
				break;
			}
			case "6months": {
				if (nowMonth - 6 === 0) {
					start_date = `${nowYear - 1}-12-${nowDate}`;
				} else if (nowMonth - 6 < 0) {
					const deltaMonths = 6 - nowMonth;
					const reversedDeltaMonths = 12 - deltaMonths;
					start_date = `${nowYear - 1}-${reversedDeltaMonths}-${nowDate}`;
				} else {
					start_date = `${nowYear}-${nowMonth - 6}-${nowDate}`;
				}
				break;
			}
			case "1year": {
				start_date = `${nowYear - 1}-${nowMonth}-${nowDate}`;
				break;
			}
			default: {
				return null;
			}
		}
		setTimeslot({ start_date, end_date });
	};

	return (
		<Wrapper>
			<div className="btns-container">
				<button className={tsActiveBtn === "1month" ? "btn active" : "btn"} onClick={handleTimeslotSelection} data-timeslot="1month">
					1month
				</button>
				<button className={tsActiveBtn === "6months" ? "btn active" : "btn"} onClick={handleTimeslotSelection} data-timeslot="6months">
					6months
				</button>
				<button className={tsActiveBtn === "1year" ? "btn active" : "btn"} onClick={handleTimeslotSelection} data-timeslot="1year">
					1year
				</button>
			</div>
			<HighchartsReact highcharts={Highcharts} options={options} />
		</Wrapper>
	);
};

export default ValuesChart;

import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { timestampFormatter } from "../data";

const VolumesChart = ({ values }) => {
	const dataTransformer = (values) => {
		return values.map((obj) => [timestampFormatter(obj.datetime), parseFloat(obj.volume)]);
	};

	const valuesTransformed = dataTransformer(values);

	const options = {
		chart: {
			type: "column",
			backgroundColor: "rgb(10, 15, 20)",
			height: 200,
		},
		title: {
			text: null,
		},
		subtitle: {
			text: null,
		},
		xAxis: {
			title: {
				enabled: false,
			},
			type: "datetime",
			dateTimeLabelFormats: {
				day: {
					main: "%e %b",
				},
				month: {
					main: "%b '%y",
				},
			},
			tickInterval: 0.1,
			lineColor: "#e45116f6",
			tickColor: "#e45116f6",
			labels: {
				style: {
					color: "#e45116f6",
				},
			},
			gridLineColor: "#e45116f6",
		},
		yAxis: {
			opposite: true,
			title: {
				enabled: false,
			},
			labels: {
				style: {
					color: "#e45116f6",
				},
			},
			gridLineColor: "#e45116f6",
		},
		legend: {
			enabled: false,
		},
		tooltip: {
			pointFormat: "Volume: <b>{point.y:.1f}</b>",
		},
		series: [
			{
				color: "#e45116f6",
				data: valuesTransformed,
			},
		],
	};

	return (
		<div>
			<HighchartsReact highcharts={Highcharts} options={options} />
		</div>
	);
};

export default VolumesChart;

const pairsTimeSeries = [
	{
		symbol: "AUD/USD",
		currency_group: "Major",
		currency_base: "Australian Dollar",
		currency_quote: "US Dollar",
		timeSerie: [
			{
				datetime: "2024-04-10",
				open: "0.66290",
				high: "0.66320",
				low: "0.65060",
				close: "0.65080",
			},
			{
				datetime: "2024-04-09",
				open: "0.66030",
				high: "0.66445",
				low: "0.65975",
				close: "0.66290",
			},
			{
				datetime: "2024-04-08",
				open: "0.65710",
				high: "0.66110",
				low: "0.65595",
				close: "0.66045",
			},
			{
				datetime: "2024-04-06",
				open: "0.65800",
				high: "0.65830",
				low: "0.65780",
				close: "0.65780",
			},
			{
				datetime: "2024-04-05",
				open: "0.65810",
				high: "0.65930",
				low: "0.65490",
				close: "0.65800",
			},
			{
				datetime: "2024-04-04",
				open: "0.65625",
				high: "0.66195",
				low: "0.65610",
				close: "0.65800",
			},
			{
				datetime: "2024-04-03",
				open: "0.65155",
				high: "0.65705",
				low: "0.65030",
				close: "0.65620",
			},
			{
				datetime: "2024-04-02",
				open: "0.64880",
				high: "0.65245",
				low: "0.64830",
				close: "0.65160",
			},
			{
				datetime: "2024-04-01",
				open: "0.65210",
				high: "0.65390",
				low: "0.64810",
				close: "0.64880",
			},
			{
				datetime: "2024-03-30",
				open: "0.65200",
				high: "0.65255",
				low: "0.65100",
				close: "0.65220",
			},
			{
				datetime: "2024-03-29",
				open: "0.65160",
				high: "0.65335",
				low: "0.65045",
				close: "0.65200",
			},
			{
				datetime: "2024-03-28",
				open: "0.65330",
				high: "0.65410",
				low: "0.64850",
				close: "0.65170",
			},
			{
				datetime: "2024-03-27",
				open: "0.65310",
				high: "0.65390",
				low: "0.65110",
				close: "0.65335",
			},
			{
				datetime: "2024-03-26",
				open: "0.65375",
				high: "0.65600",
				low: "0.65310",
				close: "0.65310",
			},
			{
				datetime: "2024-03-25",
				open: "0.65140",
				high: "0.65470",
				low: "0.65100",
				close: "0.65375",
			},
			{
				datetime: "2024-03-23",
				open: "0.65125",
				high: "0.65160",
				low: "0.65120",
				close: "0.65150",
			},
			{
				datetime: "2024-03-22",
				open: "0.65690",
				high: "0.65770",
				low: "0.65105",
				close: "0.65130",
			},
			{
				datetime: "2024-03-21",
				open: "0.65840",
				high: "0.66345",
				low: "0.65610",
				close: "0.65695",
			},
			{
				datetime: "2024-03-20",
				open: "0.65320",
				high: "0.65865",
				low: "0.65110",
				close: "0.65855",
			},
			{
				datetime: "2024-03-19",
				open: "0.65570",
				high: "0.65645",
				low: "0.65035",
				close: "0.65325",
			},
			{
				datetime: "2024-03-18",
				open: "0.65600",
				high: "0.65745",
				low: "0.65500",
				close: "0.65570",
			},
			{
				datetime: "2024-03-16",
				open: "0.65610",
				high: "0.65630",
				low: "0.65600",
				close: "0.65610",
			},
			{
				datetime: "2024-03-15",
				open: "0.65830",
				high: "0.65850",
				low: "0.65520",
				close: "0.65605",
			},
			{
				datetime: "2024-03-14",
				open: "0.66220",
				high: "0.66320",
				low: "0.65695",
				close: "0.65825",
			},
			{
				datetime: "2024-03-13",
				open: "0.66050",
				high: "0.66355",
				low: "0.66000",
				close: "0.66210",
			},
			{
				datetime: "2024-03-12",
				open: "0.66110",
				high: "0.66390",
				low: "0.65840",
				close: "0.66040",
			},
			{
				datetime: "2024-03-11",
				open: "0.66250",
				high: "0.66270",
				low: "0.65960",
				close: "0.66110",
			},
			{
				datetime: "2024-03-09",
				open: "0.66250",
				high: "0.66280",
				low: "0.66175",
				close: "0.66250",
			},
			{
				datetime: "2024-03-08",
				open: "0.66220",
				high: "0.66670",
				low: "0.66130",
				close: "0.66255",
			},
			{
				datetime: "2024-03-07",
				open: "0.65650",
				high: "0.66255",
				low: "0.65610",
				close: "0.66215",
			},
		],
	},
	{
		symbol: "CAD/USD",
		currency_group: "Major",
		currency_base: "Canadian Dollar",
		currency_quote: "US Dollar",
		timeSerie: [
			{
				datetime: "2024-04-10",
				open: "0.73685",
				high: "0.73756",
				low: "0.73020",
				close: "0.73036",
			},
			{
				datetime: "2024-04-09",
				open: "0.73682",
				high: "0.73814",
				low: "0.73535",
				close: "0.73685",
			},
			{
				datetime: "2024-04-08",
				open: "0.73516",
				high: "0.73689",
				low: "0.73440",
				close: "0.73677",
			},
			{
				datetime: "2024-04-06",
				open: "0.73577",
				high: "0.73596",
				low: "0.73570",
				close: "0.73583",
			},
			{
				datetime: "2024-04-05",
				open: "0.73785",
				high: "0.73851",
				low: "0.73274",
				close: "0.73584",
			},
			{
				datetime: "2024-04-04",
				open: "0.73895",
				high: "0.74195",
				low: "0.73756",
				close: "0.73784",
			},
			{
				datetime: "2024-04-03",
				open: "0.73678",
				high: "0.74007",
				low: "0.73592",
				close: "0.73889",
			},
			{
				datetime: "2024-04-02",
				open: "0.73662",
				high: "0.73760",
				low: "0.73620",
				close: "0.73682",
			},
			{
				datetime: "2024-04-01",
				open: "0.73845",
				high: "0.73990",
				low: "0.73605",
				close: "0.73655",
			},
			{
				datetime: "2024-03-30",
				open: "0.73845",
				high: "0.73879",
				low: "0.73820",
				close: "0.73845",
			},
			{
				datetime: "2024-03-29",
				open: "0.73825",
				high: "0.73915",
				low: "0.73763",
				close: "0.73821",
			},
			{
				datetime: "2024-03-28",
				open: "0.73682",
				high: "0.73931",
				low: "0.73455",
				close: "0.73834",
			},
			{
				datetime: "2024-03-27",
				open: "0.73601",
				high: "0.73685",
				low: "0.73475",
				close: "0.73682",
			},
			{
				datetime: "2024-03-26",
				open: "0.73608",
				high: "0.73783",
				low: "0.73585",
				close: "0.73598",
			},
			{
				datetime: "2024-03-25",
				open: "0.73479",
				high: "0.73677",
				low: "0.73455",
				close: "0.73608",
			},
			{
				datetime: "2024-03-23",
				open: "0.73470",
				high: "0.73511",
				low: "0.73470",
				close: "0.73502",
			},
			{
				datetime: "2024-03-22",
				open: "0.73887",
				high: "0.73966",
				low: "0.73454",
				close: "0.73475",
			},
			{
				datetime: "2024-03-21",
				open: "0.74151",
				high: "0.74308",
				low: "0.73852",
				close: "0.73896",
			},
			{
				datetime: "2024-03-20",
				open: "0.73706",
				high: "0.74165",
				low: "0.73495",
				close: "0.74151",
			},
			{
				datetime: "2024-03-19",
				open: "0.73864",
				high: "0.73911",
				low: "0.73462",
				close: "0.73708",
			},
			{
				datetime: "2024-03-18",
				open: "0.73862",
				high: "0.73958",
				low: "0.73795",
				close: "0.73864",
			},
			{
				datetime: "2024-03-16",
				open: "0.73830",
				high: "0.73855",
				low: "0.73825",
				close: "0.73847",
			},
			{
				datetime: "2024-03-15",
				open: "0.73906",
				high: "0.74015",
				low: "0.73785",
				close: "0.73821",
			},
			{
				datetime: "2024-03-14",
				open: "0.74233",
				high: "0.74291",
				low: "0.73849",
				close: "0.73901",
			},
			{
				datetime: "2024-03-13",
				open: "0.74116",
				high: "0.74291",
				low: "0.74075",
				close: "0.74238",
			},
			{
				datetime: "2024-03-12",
				open: "0.74173",
				high: "0.74251",
				low: "0.73946",
				close: "0.74116",
			},
			{
				datetime: "2024-03-11",
				open: "0.74171",
				high: "0.74238",
				low: "0.74020",
				close: "0.74164",
			},
			{
				datetime: "2024-03-09",
				open: "0.74164",
				high: "0.74164",
				low: "0.74093",
				close: "0.74145",
			},
			{
				datetime: "2024-03-08",
				open: "0.74304",
				high: "0.74485",
				low: "0.74089",
				close: "0.74154",
			},
			{
				datetime: "2024-03-07",
				open: "0.73985",
				high: "0.74323",
				low: "0.73935",
				close: "0.74315",
			},
		],
	},
	{
		symbol: "CHF/USD",
		currency_group: "Major",
		currency_base: "Swiss Franc",
		currency_quote: "US Dollar",
		timeSerie: [
			{
				datetime: "2024-04-10",
				open: "1.10678",
				high: "1.10757",
				low: "1.09526",
				close: "1.09541",
			},
			{
				datetime: "2024-04-09",
				open: "1.10430",
				high: "1.10941",
				low: "1.10419",
				close: "1.10676",
			},
			{
				datetime: "2024-04-08",
				open: "1.10673",
				high: "1.10826",
				low: "1.10315",
				close: "1.10447",
			},
			{
				datetime: "2024-04-06",
				open: "1.10820",
				high: "1.10869",
				low: "1.10810",
				close: "1.10850",
			},
			{
				datetime: "2024-04-05",
				open: "1.10935",
				high: "1.11137",
				low: "1.10249",
				close: "1.10841",
			},
			{
				datetime: "2024-04-04",
				open: "1.10710",
				high: "1.10978",
				low: "1.10198",
				close: "1.10945",
			},
			{
				datetime: "2024-04-03",
				open: "1.10169",
				high: "1.10736",
				low: "1.09954",
				close: "1.10702",
			},
			{
				datetime: "2024-04-02",
				open: "1.10553",
				high: "1.10628",
				low: "1.09992",
				close: "1.10153",
			},
			{
				datetime: "2024-04-01",
				open: "1.10887",
				high: "1.11037",
				low: "1.10414",
				close: "1.10548",
			},
			{
				datetime: "2024-03-30",
				open: "1.10951",
				high: "1.10951",
				low: "1.10803",
				close: "1.10886",
			},
			{
				datetime: "2024-03-29",
				open: "1.10896",
				high: "1.11046",
				low: "1.10711",
				close: "1.10878",
			},
			{
				datetime: "2024-03-28",
				open: "1.10535",
				high: "1.11107",
				low: "1.10303",
				close: "1.10882",
			},
			{
				datetime: "2024-03-27",
				open: "1.10612",
				high: "1.10701",
				low: "1.10220",
				close: "1.10532",
			},
			{
				datetime: "2024-03-26",
				open: "1.11205",
				high: "1.11270",
				low: "1.10593",
				close: "1.10602",
			},
			{
				datetime: "2024-03-25",
				open: "1.11459",
				high: "1.11490",
				low: "1.11180",
				close: "1.11226",
			},
			{
				datetime: "2024-03-23",
				open: "1.11359",
				high: "1.11542",
				low: "1.11358",
				close: "1.11445",
			},
			{
				datetime: "2024-03-22",
				open: "1.11347",
				high: "1.11476",
				low: "1.10884",
				close: "1.11343",
			},
			{
				datetime: "2024-03-21",
				open: "1.12766",
				high: "1.13132",
				low: "1.11189",
				close: "1.11340",
			},
			{
				datetime: "2024-03-20",
				open: "1.12590",
				high: "1.12867",
				low: "1.12112",
				close: "1.12779",
			},
			{
				datetime: "2024-03-19",
				open: "1.12550",
				high: "1.12769",
				low: "1.12376",
				close: "1.12572",
			},
			{
				datetime: "2024-03-18",
				open: "1.13190",
				high: "1.13353",
				low: "1.12550",
				close: "1.12567",
			},
			{
				datetime: "2024-03-16",
				open: "1.13177",
				high: "1.13201",
				low: "1.13132",
				close: "1.13181",
			},
			{
				datetime: "2024-03-15",
				open: "1.13172",
				high: "1.13381",
				low: "1.12976",
				close: "1.13168",
			},
			{
				datetime: "2024-03-14",
				open: "1.13796",
				high: "1.13917",
				low: "1.13102",
				close: "1.13146",
			},
			{
				datetime: "2024-03-13",
				open: "1.13957",
				high: "1.14069",
				low: "1.13761",
				close: "1.13796",
			},
			{
				datetime: "2024-03-12",
				open: "1.13962",
				high: "1.14307",
				low: "1.13705",
				close: "1.13946",
			},
			{
				datetime: "2024-03-11",
				open: "1.13994",
				high: "1.14218",
				low: "1.13714",
				close: "1.13948",
			},
			{
				datetime: "2024-03-09",
				open: "1.13993",
				high: "1.14000",
				low: "1.13924",
				close: "1.13931",
			},
			{
				datetime: "2024-03-08",
				open: "1.13977",
				high: "1.14541",
				low: "1.13887",
				close: "1.13980",
			},
			{
				datetime: "2024-03-07",
				open: "1.13334",
				high: "1.13991",
				low: "1.13298",
				close: "1.13983",
			},
		],
	},
];

const availablePairs = [
	{
		label: "Australian Dollar",
		code: "AUD",
	},
	{
		label: "Canadian Dollar",
		code: "CAD",
	},
	{
		label: "Swiss Franc",
		code: "CHF",
	},
	{
		label: "Euro",
		code: "EUR",
	},
	{
		label: "British Pound",
		code: "GBP",
	},
	{
		label: "Japanese Yen",
		code: "JPY",
	},
	{
		label: "New Zealand Dollar",
		code: "NZD",
	},
	{
		label: "US Dollar",
		code: "USD",
	},
];
export { pairsTimeSeries, availablePairs };

import React, { useEffect, useState } from "react";
import { Wrapper } from "../css/PageGlobalStyle";
import axios from "axios";
import IndexesDashboard from "../subcomponents/IndexesDashboard";
import IndexSelection from "../subcomponents/IndexSelection";
import StockHeader from "../subcomponents/StockHeader";
import ValuesChart from "../subcomponents/ValuesChart";
import VolumesChart from "../subcomponents/VolumesChart";
import "react-toastify/dist/ReactToastify.css";
import { StyledToast, notify } from "../css/ToastStyle";
import { SlClose } from "react-icons/sl";
import ApiLimitsMessage from "../subcomponents/ApiLimitsMessage";

const Indexes = () => {
	const [someTickers, setSomeTickers] = useState();
	const [selectedTicker, setSelectedTicker] = useState();
	const [selectedTickerData, setSelectedTickerData] = useState();
	const [selectedTickerLogoUrl, setSelectedTickerLogoUrl] = useState();
	const [selectedTickerEodPrice, setSelectedTickerEodPrice] = useState();
	const [page, setPage] = useState(0);
	const [apiCallsLimitReachedMessage, setApiCallsLimitReachedMessage] = useState();
	const [timeslot, setTimeslot] = useState();
	const [openModal, setOpenModal] = useState(false);

	const fetchIndexes = async () => {
		try {
			let targetUrl = process.env.REACT_APP_NODE_URL_BASE;
			const firstSegment = `indices`;
			targetUrl += firstSegment;
			const res = await axios.get(targetUrl);
			const newRez = {
				count: res.data.data.filter((obj) => obj.access.plan === "Basic").length,
				data: res.data.data.filter((obj) => obj.access.plan === "Basic"),
				status: res.data.status,
			};
			setSomeTickers(newRez);
		} catch (error) {
			console.log(error);
		}
	};

	const fetchIndexHistory = async (symb, timeslot) => {
		try {
			let targetUrl = process.env.REACT_APP_NODE_URL_BASE;
			const firstSegment = `time_series`;
			const secondSegment = `symbol=${symb}`;
			targetUrl += firstSegment + `?` + secondSegment;
			if (timeslot) {
				targetUrl += `&` + `start_date=${timeslot.start_date}` + `&` + `end_date=${timeslot.end_date}`;
			}
			const res = await axios.get(targetUrl);
			if (res.data.code === 429) {
				notify();
				setApiCallsLimitReachedMessage(res.data.message);
			} else {
				// else remove the message :
				setApiCallsLimitReachedMessage("");
			}
			setSelectedTickerData(res.data);
			setOpenModal(true);
		} catch (error) {
			console.log(error);
		}
	};

	const fetchSelectedIndexLogo = async (symb) => {
		try {
			let targetUrl = process.env.REACT_APP_NODE_URL_BASE;
			const firstSegment = `logo`;
			const secondSegment = `symbol=${symb}`;
			targetUrl += firstSegment + `?` + secondSegment;
			const res = await axios.get(targetUrl);
			setSelectedTickerLogoUrl(res.data.url);
		} catch (error) {
			console.log(error);
		}
	};

	const fetchSelectedIndexLatestEODPrice = async (symb) => {
		try {
			let targetUrl = process.env.REACT_APP_NODE_URL_BASE;
			const firstSegment = `eod`;
			const secondSegment = `symbol=${symb}`;
			targetUrl += firstSegment + `?` + secondSegment;
			const res = await axios.get(targetUrl);
			setSelectedTickerEodPrice(res.data);
		} catch (error) {
			console.log(error);
		}
	};

	const getMaxPage = () => {
		// lets take 100 stocks per page
		if (someTickers && someTickers.data) {
			const rest = someTickers.data.length % 50;
			if (rest === 0) {
				return someTickers.data.length / 50;
			} else {
				return Math.floor(someTickers.data.length / 50) + 1;
			}
		}
	};

	const increasePage = () => {
		const maxPage = getMaxPage();
		if (page < maxPage - 1) {
			setPage((oldState) => oldState + 1);
		}
	};

	const decreasePage = () => {
		if (page > 0) {
			setPage((oldState) => oldState - 1);
		}
	};

	const goToMinPage = () => {
		setPage(0);
	};

	const goToMaxPage = () => {
		setPage(getMaxPage() - 1);
	};

	useEffect(() => {
		fetchIndexes();
	}, []);

	useEffect(() => {
		if (selectedTicker) {
			fetchIndexHistory(selectedTicker.symbol, timeslot);
			fetchSelectedIndexLogo(selectedTicker.symbol);
			fetchSelectedIndexLatestEODPrice(selectedTicker.symbol);
		}
	}, [selectedTicker, timeslot]);

	const checkMiddle1Classes = () => {
		let classes = "middle-1";
		const windowWidth = window.innerWidth;
		if (openModal && windowWidth < 1200) {
			classes += " hide";
		}
		return classes;
	};

	const checkModalClasses = () => {
		// console.log("Check Modal Classes Func");
		let classes = "close-icon";
		const windowWidth = window.innerWidth;
		if (openModal && windowWidth < 1200) {
			classes += " show";
		}
		// console.log(classes);
		return classes;
	};

	const closeGraphModal = () => {
		setSelectedTickerData(null);
		// setSelectedStock(null);
		setOpenModal(false);
		const middleElt = document.getElementById("middle-1");
		middleElt.classList.add("show");
		middleElt.classList.remove("hide");
	};

	window.addEventListener("resize", () => {
		const windowWidth = window.innerWidth;
		const closeIconElt = document.getElementById("close-icon");
		const middleElt = document.getElementById("middle-1");
		if (closeIconElt) {
			if (windowWidth > 1200) {
				closeIconElt.classList.remove("show");
			} else {
				if (openModal) {
					closeIconElt.classList.add("show");
				}
			}
		}
		if (middleElt) {
			if (windowWidth > 1200) {
				middleElt.classList.add("show");
				middleElt.classList.remove("hide");
			} else {
				if (openModal) {
					middleElt.classList.add("hide");
					middleElt.classList.remove("show");
				} else {
					middleElt.classList.add("show");
					middleElt.classList.remove("hide");
				}
			}
		}
	});

	const handleTickerSelection = (symb) => {
		// console.log(symb);
		setSelectedTicker(symb);
	};

	// console.log(someTickers);
	// console.log(selectedTicker);

	return (
		<Wrapper>
			<StyledToast />
			<div className="left-1">
				<IndexSelection handleTickerSelection={handleTickerSelection} />
			</div>
			<div className={checkMiddle1Classes()} id="middle-1">
				<IndexesDashboard
					indexes={someTickers}
					handleTickerSelection={handleTickerSelection}
					selectedTicker={selectedTicker}
					page={page}
					maxPage={getMaxPage()}
					increasePage={increasePage}
					decreasePage={decreasePage}
					goToMaxPage={goToMaxPage}
					goToMinPage={goToMinPage}
				/>
			</div>
			{apiCallsLimitReachedMessage && (
				<div className="right-1 limit-error">
					<ApiLimitsMessage message={apiCallsLimitReachedMessage} setApiCallsLimitReachedMessage={setApiCallsLimitReachedMessage} />
				</div>
			)}
			{!apiCallsLimitReachedMessage && selectedTickerData && selectedTickerData.meta && selectedTickerData.status === "ok" && (
				<div className="right-1">
					<SlClose id="close-icon" className={checkModalClasses()} onClick={closeGraphModal} />
					<StockHeader
						tickerMeta={selectedTickerData.meta}
						ticker={selectedTicker}
						logoUrl={selectedTickerLogoUrl}
						key={selectedTickerData.meta.symbol}
						eodPrice={selectedTickerEodPrice}
					/>
					<ValuesChart values={selectedTickerData.values} key={selectedTickerData.meta.symbol + 1} setTimeslot={setTimeslot} />
					<VolumesChart values={selectedTickerData.values} key={selectedTickerData.meta.symbol + 2} />
				</div>
			)}
		</Wrapper>
	);
};

export default Indexes;

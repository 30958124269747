import React from "react";
import styled from "styled-components";
import { BiLogoNetlify } from "react-icons/bi";

const Wrapper = styled.section`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	align-items: center;
	letter-spacing: 1rem;
	margin-top: 5rem;
	margin-bottom: 1rem;
	font-size: 3rem;
	.name-container {
		align-self: center;
	}
	.date-container {
		display: flex;
		align-items: center;
		/* border: 2px solid white; */
	}
	.socials-container {
		display: grid;
		/* border: 2px solid white; */
		place-content: center;
	}
	a {
		color: inherit;
	}
	@media screen and (max-width: 1100px) {
		font-size: 2rem;
	}
	@media screen and (max-width: 900px) {
		display: flex;
		flex-direction: column;
	}
`;

const Footer = () => {
	const date = new Date().getFullYear();
	return (
		<Wrapper>
			<div className="name-container" style={{ justifyContent: "start" }}>
				made by Hadley
			</div>
			<div className="date-container" style={{ justifyContent: "center" }}>
				<span>{`{`}</span>&nbsp;
				<span style={{ marginBottom: "0.5rem" }}>{date}</span>&nbsp;
				<span>{` }`}</span>
			</div>
			<div className="socials-container" style={{ justifyContent: "end" }}>
				<a href="https://hadley-portfolio.netlify.app/" target="_blank">
					<BiLogoNetlify />
				</a>
			</div>
		</Wrapper>
	);
};

export default Footer;

import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Wrapper = styled.article`
	/* border: 1px solid white; */
	width: 100%;
	display: flex;
	gap: 1.5rem;
	flex-direction: column;
	margin-top: 2.2rem;
	margin-bottom: 2.2rem;
	.title-container {
		display: flex;
		align-items: center;
		gap: 1rem;
		.title {
			font-size: 3rem;
		}
		.logo {
			width: 4rem;
			height: 4rem;
			border-radius: 50%;
		}
	}
	.ticker-info {
		display: flex;
		align-items: center;
		gap: 1rem;
	}
	.eod-container {
		display: flex;
		gap: 2rem;
	}
`;

const StockHeader = ({ ticker, tickerMeta, logoUrl, eodPrice, mode }) => {
	const { exchange_timezone, mic_code, symbol } = tickerMeta;
	let name;
	if (typeof ticker === "object") {
		// console.log("case1");
		name = ticker.name || ticker.instrument_name;
	} else {
		// console.log("case2");
		name = ticker;
	}
	// console.log(ticker);
	// console.log(tickerMeta);

	return (
		<Wrapper>
			<div className="title-container">
				{logoUrl && <img src={logoUrl} alt="logo" className="logo" />}
				<span className="title">{name}</span>
			</div>

			{tickerMeta && (
				<div className="ticker-info">
					<span>{exchange_timezone}</span>
					<span>{mic_code}</span>
					<span>{symbol}</span>
				</div>
			)}
			{eodPrice && (
				<div className="eod-container">
					<span>{eodPrice.exchange}</span>
					<span>{eodPrice.datetime}</span>
					<span>Last closure price: {mode === "parity" ? <>{parseFloat(eodPrice.close).toFixed(4)}</> : <>{Math.round(eodPrice.close)}</>}</span>
					<span>{eodPrice.currency}</span>
					<span></span>
				</div>
			)}
		</Wrapper>
	);
};

export default StockHeader;

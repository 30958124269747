const currencynameToSymbolMapper = [
	{ name: "Australian Dollar", code: "AUD" },
	{ name: "Canadian Dollar", code: "CAD" },
	{ name: "Swiss Franc", code: "CHF" },
	{ name: "Euro", code: "EUR" },
	{ name: "British Pound", code: "GBP" },
	{ name: "Japanese Yen", code: "JPY" },
	{ name: "New Zealand Dollar", code: "NZD" },
	{ name: "US Dollar", code: "USD" },
];

export { currencynameToSymbolMapper };

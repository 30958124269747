import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Stockchart from "highcharts/modules/stock";
import { timestampFormatter, serie1, serie2 } from "../data";
import styled from "styled-components";

Stockchart(Highcharts);

const Wrapper = styled.div`
	/* border: 1px solid red; */
	position: relative;
	width: 100%;
	.btns-container {
		display: flex;
		gap: 0.5rem;
		.btn {
			background-color: var(--font-5);
			border: none;
			outline: none;
			padding: 0.5rem;
			cursor: pointer;
			&:hover {
				background-color: var(--font-2);
			}
		}
		.active {
			background-color: var(--font-2);
		}
	}
`;

const ForexMainPairsChart = ({ pairsSeries, setTimeslot }) => {
	const [localPairsSeries, setLocalPairsSeries] = useState(pairsSeries);
	const [tsActiveBtn, setTsActiveBtn] = useState("1month");

	const seriesFormatter = (list) => {
		let series = list.map((obj) => {
			let serie = obj.timeSerie?.map((obj) => [timestampFormatter(obj.datetime), parseFloat(obj.close)]).reverse();
			return { name: obj.symbol, data: serie };
		});
		return series;
	};

	const handleTimeslotSelection = (evt) => {
		const ts = evt.target.dataset.timeslot;
		setTsActiveBtn(ts);
		const now = new Date();
		const nowYear = now.getFullYear();
		const nowMonth = now.getMonth() + 1;
		const nowDate = now.getDate();
		const end_date = `${nowYear}-${nowMonth}-${nowDate}`;
		let start_date = "";
		switch (ts) {
			case "1month": {
				if (nowMonth === 1) {
					start_date = `${nowYear - 1}-12-${nowDate}`;
				} else {
					start_date = `${nowYear}-${nowMonth - 1}-${nowDate}`;
				}
				break;
			}
			case "6months": {
				if (nowMonth - 6 === 0) {
					start_date = `${nowYear - 1}-12-${nowDate}`;
				} else if (nowMonth - 6 < 0) {
					const deltaMonths = 6 - nowMonth;
					const reversedDeltaMonths = 12 - deltaMonths;
					start_date = `${nowYear - 1}-${reversedDeltaMonths}-${nowDate}`;
				} else {
					start_date = `${nowYear}-${nowMonth - 6}-${nowDate}`;
				}
				break;
			}
			case "1year": {
				start_date = `${nowYear - 1}-${nowMonth}-${nowDate}`;
				break;
			}
			default: {
				return null;
			}
		}
		//console.log({ start_date, end_date });
		setTimeslot({ start_date, end_date });
	};

	useEffect(() => {
		if (pairsSeries) {
			setLocalPairsSeries(seriesFormatter(pairsSeries));
		}
	}, [pairsSeries]);

	const options = {
		rangeSelector: {
			selected: 4,
		},

		xAxis: {
			title: {
				enabled: false,
			},
			type: "datetime",
			dateTimeLabelFormats: {
				day: {
					main: "%e %b",
				},
				month: {
					main: "%b '%y",
				},
			},
			tickInterval: 1,
			lineColor: "#e45116f6",
			tickColor: "#e45116f6",
			labels: {
				style: {
					color: "#e45116f6",
				},
			},
		},

		yAxis: {
			opposite: true,
			title: {
				enabled: false,
			},
			labels: {
				style: {
					color: "#e45116f6",
				},
				format: "{#if (gt value 0)}+{/if}{value}%",
			},
			plotLines: [
				{
					value: 0,
					width: 2,
					color: "silver",
				},
			],
			gridLineColor: "#e45116f6",
		},

		legend: {
			enabled: true,
			itemStyle: {
				color: "#e45116f6",
				fontSize: "2rem",
			},
		},

		title: {
			text: null,
		},

		chart: {
			backgroundColor: "rgb(10, 15, 20)",
		},

		plotOptions: {
			series: {
				compare: "percent",
				showInNavigator: true,
			},
		},

		tooltip: {
			style: {
				fontSize: "1.8rem",
			},
			pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.change}%)<br/>',
			valueDecimals: 2,
			split: true,
			crosshairs: {
				color: "green",
				dashStyle: "solid",
			},
			shared: true,
		},

		series: localPairsSeries,
	};

	return (
		<Wrapper>
			<div className="btns-container">
				<button className={tsActiveBtn === "1month" ? "btn active" : "btn"} onClick={handleTimeslotSelection} data-timeslot="1month">
					1month
				</button>
				<button className={tsActiveBtn === "6months" ? "btn active" : "btn"} onClick={handleTimeslotSelection} data-timeslot="6months">
					6months
				</button>
				<button className={tsActiveBtn === "1year" ? "btn active" : "btn"} onClick={handleTimeslotSelection} data-timeslot="1year">
					1year
				</button>
			</div>
			<HighchartsReact highcharts={Highcharts} options={options} />
		</Wrapper>
	);
};

export default ForexMainPairsChart;

import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import logo from "../images/money-alt-svgrepo-com.svg";
import { BsInfoCircle } from "react-icons/bs";

const Wrapper = styled.section`
	/* border: 1px solid white; */
	width: 100%;
	/* padding: 0 1rem; */
	display: flex;
	align-items: center;
	.logo-container {
		width: 4rem;
		height: 4rem;
		display: grid;
		place-content: center;
		.logo {
			width: 100%;
			height: 100%;
			cursor: pointer;
			transition: all 0.2s ease-in-out;
			&:hover {
				transform: scale(1.1);
			}
		}
	}
	.title-container {
		padding: 1rem 0 1.6rem 0;
		letter-spacing: 1rem;
	}
	.right-align {
		/* border: 1px solid white; */
		margin-left: auto;
		/* padding: 0.5rem; */
	}
	.info-icon-container {
		width: 2rem !important;
		height: 2rem !important;
	}
	@media screen and (max-width: 600px) {
		font-size: 3rem;
		.logo-container {
			width: 3rem;
			height: 3rem;
		}
	}
	@media screen and (max-width: 400px) {
		font-size: 2.5rem;
		.logo-container {
			width: 2.5rem;
			height: 2.5rem;
		}
	}
`;

const Header = () => {
	const navigate = useNavigate();

	const handleNavigateRoot = () => {
		navigate(`/`);
	};

	const handleNavigateDisclaimer = () => {
		navigate(`/disclaimer`);
	};

	return (
		<Wrapper>
			<div className="logo-container">
				<img src={logo} alt="logo" className="logo" onClick={handleNavigateRoot} />
			</div>
			<div className="title-container">
				<div>stocksTracker</div>
				{/* <div>the board you need to track stocks, macro economics and finance figures</div> */}
			</div>
			<div className="logo-container right-align info-icon-container">
				<BsInfoCircle className="logo" onClick={handleNavigateDisclaimer} />
			</div>
		</Wrapper>
	);
};

export default Header;

import React, { useEffect, useState } from "react";
import { Wrapper } from "../css/PageGlobalStyle";
import axios from "axios";
import StockHeader from "../subcomponents/StockHeader";
import CountrySelection from "../subcomponents/CountrySelection";
import SearchSelection from "../subcomponents/SearchSelection";
import DisplaySearchList from "../subcomponents/DisplaySearchList";
import StocksDashboard from "../subcomponents/StocksDashboard";
import ValuesChart from "../subcomponents/ValuesChart";
import VolumesChart from "../subcomponents/VolumesChart";
import "react-toastify/dist/ReactToastify.css";
import { StyledToast, notify } from "../css/ToastStyle";
import { SlClose } from "react-icons/sl";
import ApiLimitsMessage from "../subcomponents/ApiLimitsMessage";

const Stocks = () => {
	const [selectedRegion, setSelectedRegion] = useState("europe");
	const [selectedCountry, setSelectedCountry] = useState();
	const [selectedMarket, setSelectedMarket] = useState();
	const [selectedStocks, setSelectedStocks] = useState();
	const [selectedStock, setSelectedStock] = useState();
	const [page, setPage] = useState(0);
	const [searchedSymbol, setSearchedSymbol] = useState();
	const [searchedSymbolLogoUrl, setSearchedSymbolLogoUrl] = useState();
	const [searchedSymbolEodPrice, setsearchedSymbolEodPrice] = useState();
	const [apiCallsLimitReachedMessage, setApiCallsLimitReachedMessage] = useState();
	const [timeslot, setTimeslot] = useState();
	const [openModal, setOpenModal] = useState(false);

	const fetchCountryMarketMicStocks = async (country, market, mic) => {
		try {
			let targetUrl = process.env.REACT_APP_NODE_URL_BASE;
			const firstSegment = `stocks`;
			const secondSegment = `country=${country}`;
			const thirdSegment = `exchange=${market}`;
			const fourthSegment = `mic_code=${mic}`;
			targetUrl += firstSegment + `?` + secondSegment;
			if (market) {
				targetUrl += `&` + thirdSegment;
			}
			if (mic) {
				targetUrl += `&` + fourthSegment;
			}
			// console.log(targetUrl);
			const res = await axios.get(targetUrl);
			// console.log(res.data);
			setSelectedStocks(res.data);
			setOpenModal(false);
			setSearchedSymbol(null);
		} catch (error) {
			console.log(error);
		}
	};

	const fetchAutoCompletion = async (term) => {
		try {
			let targetUrl = process.env.REACT_APP_NODE_URL_BASE;
			const firstSegment = `symbol_search`;
			const secondSegment = `symbol=${term}`;
			targetUrl += firstSegment + `?` + secondSegment;
			const res = await axios.get(targetUrl);
			const newRez = {
				data: res.data.data.filter((obj) => obj.access.plan === "Basic"),
				count: res.data.data.filter((obj) => obj.access.plan === "Basic").length,
				mode: "search-term",
			};
			setSelectedStocks(newRez);
			setOpenModal(false);
			setSearchedSymbol(null);
		} catch (error) {
			console.log(error);
		}
	};

	const fetchSearchedSymbolData = async (symb, timeslot) => {
		const { symbol } = symb;
		try {
			let targetUrl = process.env.REACT_APP_NODE_URL_BASE;
			const firstSegment = `time_series`;
			const secondSegment = `symbol=${symbol}`;
			targetUrl += firstSegment + `?` + secondSegment;
			if (timeslot) {
				targetUrl += `&` + `start_date=${timeslot.start_date}` + `&` + `end_date=${timeslot.end_date}`;
			}
			const res = await axios.get(targetUrl);
			// console.log(res);
			if (res.data.code === 429) {
				notify();
				setApiCallsLimitReachedMessage(res.data.message);
			} else {
				// else remove the message :
				setApiCallsLimitReachedMessage("");
			}
			setSearchedSymbol(res);
			setOpenModal(true);
		} catch (error) {
			console.log(error);
		}
	};

	const fetchSearchedSymbolLogo = async (symb) => {
		try {
			let targetUrl = process.env.REACT_APP_NODE_URL_BASE;
			const firstSegment = `logo`;
			const secondSegment = `symbol=${symb}`;
			targetUrl += firstSegment + `?` + secondSegment;
			const res = await axios.get(targetUrl);
			setSearchedSymbolLogoUrl(res.data.url);
		} catch (error) {
			console.log(error);
		}
	};

	const fetchSearchedSymbolLatestEODPrice = async (symb) => {
		try {
			let targetUrl = process.env.REACT_APP_NODE_URL_BASE;
			const firstSegment = `eod`;
			const secondSegment = `symbol=${symb}`;
			targetUrl += firstSegment + `?` + secondSegment;
			const res = await axios.get(targetUrl);
			setsearchedSymbolEodPrice(res.data);
		} catch (error) {
			console.log(error);
		}
	};

	const handleRegionSelection = (evt) => {
		const regionId = evt.target.dataset.reg;
		setSelectedCountry();
		setSelectedMarket();
		setSelectedRegion(regionId);
	};

	const handleCountrySelection = (evt) => {
		const countryId = evt.target.dataset.count;
		setSelectedMarket();
		setSelectedCountry(countryId);
	};

	const handleMarketSelection = (evt) => {
		const marketId = evt.target.dataset.markt;
		setSelectedMarket(marketId);
	};

	const autoCompletion = (evt) => {
		const term = evt.target.value;
		if (term) {
			fetchAutoCompletion(term);
		}
	};

	const handleTickerSelection = (symb) => {
		setSelectedStock(symb);
	};

	const getMaxPage = () => {
		// lets take 100 stocks per page
		const rest = selectedStocks.data.length % 100;
		if (rest === 0) {
			return selectedStocks.data.length / 100;
		} else {
			return Math.floor(selectedStocks.data.length / 100) + 1;
		}
	};

	const increasePage = () => {
		const maxPage = getMaxPage();
		if (page < maxPage - 1) {
			setPage((oldState) => oldState + 1);
		}
	};

	const decreasePage = () => {
		if (page > 0) {
			setPage((oldState) => oldState - 1);
		}
	};

	const goToMinPage = () => {
		setPage(0);
	};

	const goToMaxPage = () => {
		setPage(getMaxPage() - 1);
	};

	useEffect(() => {
		if (selectedCountry) {
			fetchCountryMarketMicStocks(selectedCountry, selectedMarket);
		}
	}, [selectedCountry, selectedMarket]);

	useEffect(() => {
		if (selectedStock) {
			fetchSearchedSymbolData(selectedStock, timeslot);
			fetchSearchedSymbolLogo(selectedStock.symbol);
			fetchSearchedSymbolLatestEODPrice(selectedStock.symbol);
		}
	}, [selectedStock, timeslot]);

	// if (searchedSymbol) {
	// 	if (searchedSymbol.data.code === 429) {
	// 		console.log("RUN OUT");
	// 	} else if (searchedSymbol.data.code === 404) {
	// 		console.log("NO PLAN");
	// 	}
	// }

	const checkMiddle1Classes = () => {
		let classes = "middle-1";
		const windowWidth = window.innerWidth;
		if (openModal && windowWidth < 1200) {
			classes += " hide";
		}
		return classes;
	};

	const checkModalClasses = () => {
		// console.log("Check Modal Classes Func");
		let classes = "close-icon";
		const windowWidth = window.innerWidth;
		if (openModal && windowWidth < 1200) {
			classes += " show";
		}
		// console.log(classes);
		return classes;
	};

	const closeGraphModal = () => {
		setSearchedSymbol(null);
		setSelectedStock(null);
		setOpenModal(false);
		const middleElt = document.getElementById("middle-1");
		middleElt.classList.add("show");
		middleElt.classList.remove("hide");
	};

	window.addEventListener("resize", () => {
		const windowWidth = window.innerWidth;
		const closeIconElt = document.getElementById("close-icon");
		const middleElt = document.getElementById("middle-1");
		if (closeIconElt) {
			if (windowWidth > 1200) {
				closeIconElt.classList.remove("show");
			} else {
				if (openModal) {
					closeIconElt.classList.add("show");
				}
			}
		}
		if (middleElt) {
			if (windowWidth > 1200) {
				middleElt.classList.add("show");
				middleElt.classList.remove("hide");
			} else {
				if (openModal) {
					middleElt.classList.add("hide");
					middleElt.classList.remove("show");
				} else {
					middleElt.classList.add("show");
					middleElt.classList.remove("hide");
				}
			}
		}
	});

	// console.log(selectedStock);
	// console.log(searchedSymbol);

	return (
		<Wrapper>
			<StyledToast />
			<div className="left-1">
				<CountrySelection
					handleRegionSelection={handleRegionSelection}
					handleCountrySelection={handleCountrySelection}
					handleMarketSelection={handleMarketSelection}
					selectedRegion={selectedRegion}
					selectedCountry={selectedCountry}
					selectedMarket={selectedMarket}
				/>
			</div>

			<div className="left-3">
				<SearchSelection autoCompletion={autoCompletion} />
			</div>

			{selectedStocks ? (
				<div className={checkMiddle1Classes()} id="middle-1">
					<DisplaySearchList
						selectedStocks={selectedStocks}
						selectedStock={selectedStock}
						handleTickerSelection={handleTickerSelection}
						page={page}
						maxPage={getMaxPage()}
						increasePage={increasePage}
						decreasePage={decreasePage}
						goToMaxPage={goToMaxPage}
						goToMinPage={goToMinPage}
						selectedRegion={selectedRegion}
						selectedCountry={selectedCountry}
						selectedMarket={selectedMarket}
					/>
				</div>
			) : (
				<div className={checkMiddle1Classes()} id="middle-1">
					<StocksDashboard handleTickerSelection={handleTickerSelection} selectedStock={selectedStock} />
				</div>
			)}

			{apiCallsLimitReachedMessage && (
				<div className="right-1 limit-error">
					<ApiLimitsMessage message={apiCallsLimitReachedMessage} setApiCallsLimitReachedMessage={setApiCallsLimitReachedMessage} />
				</div>
			)}

			{!apiCallsLimitReachedMessage && searchedSymbol && searchedSymbol.data && searchedSymbol.data.status === "ok" && (
				<div className="right-1">
					<SlClose id="close-icon" className={checkModalClasses()} onClick={closeGraphModal} />
					<StockHeader
						tickerMeta={searchedSymbol.data.meta}
						ticker={selectedStock}
						key={searchedSymbol.data.meta.symbol}
						logoUrl={searchedSymbolLogoUrl}
						eodPrice={searchedSymbolEodPrice}
					/>
					<ValuesChart values={searchedSymbol.data.values} key={searchedSymbol.data.meta.symbol + 1} setTimeslot={setTimeslot} />
					<VolumesChart values={searchedSymbol.data.values} key={searchedSymbol.data.meta.symbol + 2} />
				</div>
			)}
		</Wrapper>
	);
};

export default Stocks;

import React, { useEffect, useState } from "react";
import { Wrapper } from "../css/PageGlobalStyle";
import styled from "styled-components";
import axios from "axios";
import DisplayForexPairsList from "../subcomponents/DisplayForexPairsList";
import StockHeader from "../subcomponents/StockHeader";
import ValuesChart from "../subcomponents/ValuesChart";
import CurrencyConverter from "../subcomponents/CurrencyConverter";
import ForexMainPairsChart from "../subcomponents/ForexMainPairsChart";
import "react-toastify/dist/ReactToastify.css";
import { StyledToast, notify } from "../css/ToastStyle";
import { SlClose } from "react-icons/sl";
import ApiLimitsMessage from "../subcomponents/ApiLimitsMessage";
import { IoArrowBackCircleSharp } from "react-icons/io5";

const SurWrapper = styled(Wrapper)`
	grid-template-columns: 3fr 4fr 3fr;
	@media screen and (max-width: 1200px) {
		grid-template-columns: 1fr 3fr;
	}
	@media screen and (max-width: 1000px) {
		display: flex;
		flex-direction: column;
		.right-1 {
			position: relative;
		}
	}
`;

const Forex = () => {
	const [forexPairsData, setForexPairsData] = useState();
	const [forexPairsTimeSeries, setForexPairsTimeSeries] = useState();
	const [searchedSymbol, setSearchedSymbol] = useState();
	const [apiCallsLimitReachedMessage, setApiCallsLimitReachedMessage] = useState();
	const [timeslotSingleTickerGraph, setTimeslotSingleTickerGraph] = useState();
	const [timeslotMultiTickerGraph, setTimeslotMultiTickerGraph] = useState();
	const [selectedTicker, setSelectedTicker] = useState();
	const [openModal, setOpenModal] = useState(false);
	const [forceParitiesGraphReload, setForceParitiesGraphReload] = useState(0);

	const fetchDataCenterTableSingleton = async (symbol) => {
		try {
			let targetUrl = process.env.REACT_APP_NODE_URL_BASE;
			const firstSegment = `exchange_rate`;
			const secondSegment = `symbol=${symbol}`;
			targetUrl += firstSegment + `?`;
			if (symbol) {
				targetUrl += secondSegment;
			}
			//console.log(targetUrl);
			const res = await axios.get(targetUrl);
			//console.log(res.data);
			return res.data;
			// setForexPairDataPrice(res.data);
		} catch (error) {
			console.log(error);
		}
	};

	const fetchDataCenterTable = async () => {
		const subList = [
			{
				symbol: "EUR/USD",
				currency_group: "Major",
				currency_base: "Euro",
				currency_quote: "US Dollar",
			},
			{
				symbol: "CHF/USD",
				currency_group: "Major",
				currency_base: "Swiss Franc",
				currency_quote: "US Dollar",
			},
			{
				symbol: "GBP/USD",
				currency_group: "Major",
				currency_base: "British Pound",
				currency_quote: "US Dollar",
			},
			{
				symbol: "JPY/USD",
				currency_group: "Major",
				currency_base: "Japanese Yen",
				currency_quote: "US Dollar",
			},
		];
		const newList = await Promise.all(
			subList.map(async (obj) => {
				try {
					const rate = await fetchDataCenterTableSingleton(obj.symbol);
					//console.log(rate);
					return { ...obj, rate };
				} catch (error) {
					return obj;
				}
			})
		);
		setForexPairsData(newList);
	};

	const fetchDataParitiesGraphSingleton = async (symb, mode, timeslot) => {
		try {
			let targetUrl = process.env.REACT_APP_NODE_URL_BASE;
			const firstSegment = `time_series`;
			const secondSegment = `symbol=${symb}`;
			targetUrl += firstSegment + `?` + secondSegment;
			if (timeslot) {
				targetUrl += `&` + `start_date=${timeslot.start_date}` + `&` + `end_date=${timeslot.end_date}`;
			}
			const res = await axios.get(targetUrl);
			if (res.data.code === 429) {
				notify();
				setApiCallsLimitReachedMessage(res.data.message);
			} else {
				// else remove the message :
				setApiCallsLimitReachedMessage("");
			}
			// in case we want to display one pair on right single graph
			if (mode === "manual") {
				setSearchedSymbol(res);
				setOpenModal(true);
				return;
			}
			// in case the function is used for time series on multi curves graph
			if (mode === "auto") {
				return res.data.values;
			}
		} catch (error) {
			console.log(error);
		}
	};

	const fetchDataParitiesGraph = async (timeslot) => {
		// console.log(ts);
		const subList = [
			{
				symbol: "EUR/USD",
				currency_group: "Major",
				currency_base: "Euro",
				currency_quote: "US Dollar",
			},
			{
				symbol: "GBP/USD",
				currency_group: "Major",
				currency_base: "British Pound",
				currency_quote: "US Dollar",
			},
			{
				symbol: "CHF/USD",
				currency_group: "Major",
				currency_base: "Swiss Franc",
				currency_quote: "US Dollar",
			},
		];
		const newList = await Promise.all(
			subList.map(async (obj) => {
				try {
					const timeSerie = await fetchDataParitiesGraphSingleton(obj.symbol, "auto", timeslot);
					return { ...obj, timeSerie };
				} catch (error) {
					return obj;
				}
			})
		);
		setForexPairsTimeSeries(newList);
	};

	const handleTickerSelection = (symb) => {
		// console.log(symb);
		setSelectedTicker(symb.symbol);
	};

	const checkLeft2Classes = () => {
		let classes = "left-2";
		const windowWidth = window.innerWidth;
		if (openModal && windowWidth < 1200 && windowWidth > 1000) {
			classes += " hide";
		}
		return classes;
	};

	const checkModalClasses = () => {
		let classes = "close-icon";
		const windowWidth = window.innerWidth;
		if (openModal && windowWidth < 1200) {
			classes += " show";
		}
		return classes;
	};

	const closeGraphModal = () => {
		setSearchedSymbol(null);
		setOpenModal(false);
		const left2Elt = document.getElementById("left-2");
		left2Elt.classList.add("show");
		left2Elt.classList.remove("hide");
	};

	window.addEventListener("resize", () => {
		const windowWidth = window.innerWidth;
		const closeIconElt = document.getElementById("close-icon");
		const left2Elt = document.getElementById("left-2");
		if (closeIconElt) {
			if (windowWidth > 1200) {
				closeIconElt.classList.remove("show");
			} else {
				if (openModal) {
					closeIconElt.classList.add("show");
				}
			}
		}
		if (left2Elt) {
			if (windowWidth > 1200 || windowWidth < 1000) {
				left2Elt.classList.add("show");
				left2Elt.classList.remove("hide");
			} else {
				if (openModal) {
					left2Elt.classList.add("hide");
					left2Elt.classList.remove("show");
				} else {
					left2Elt.classList.add("show");
					left2Elt.classList.remove("hide");
				}
			}
		}
	});

	const handleReload = () => {
		setForceParitiesGraphReload((oldState) => oldState + 1);
	};

	useEffect(() => {
		fetchDataCenterTable();
	}, []);

	useEffect(() => {
		fetchDataParitiesGraph(timeslotMultiTickerGraph);
	}, [timeslotMultiTickerGraph, forceParitiesGraphReload]);

	useEffect(() => {
		if (selectedTicker) {
			fetchDataParitiesGraphSingleton(selectedTicker, "manual", timeslotSingleTickerGraph);
		}
	}, [selectedTicker, timeslotSingleTickerGraph]);

	// console.log(selectedTicker);

	return (
		<SurWrapper>
			<StyledToast />
			<div className="left-1">
				<CurrencyConverter />
			</div>
			<div className={checkLeft2Classes()} id="left-2">
				{forexPairsTimeSeries && forexPairsTimeSeries.length > 0 && forexPairsTimeSeries.filter((obj) => !obj.timeSerie).length === 0 ? (
					<ForexMainPairsChart pairsSeries={forexPairsTimeSeries} setTimeslot={setTimeslotMultiTickerGraph} />
				) : (
					<div className="parities-limit-message">
						<div>Data corrupted or API limits reached. Try or refresh again later.</div>
						<div onClick={handleReload} className="back-icon-container" style={{ cursor: "pointer" }}>
							<IoArrowBackCircleSharp />
						</div>
					</div>
				)}
			</div>
			{forexPairsData && forexPairsData.length > 0 && (
				<div className="middle-1" id="middle-1">
					<DisplayForexPairsList selectedForexPairs={forexPairsData} handleTickerSelection={handleTickerSelection} selectedTicker={selectedTicker} />
				</div>
			)}
			{apiCallsLimitReachedMessage && (
				<div className="right-1 limit-error">
					<ApiLimitsMessage message={apiCallsLimitReachedMessage} setApiCallsLimitReachedMessage={setApiCallsLimitReachedMessage} />
				</div>
			)}
			{!apiCallsLimitReachedMessage && searchedSymbol && searchedSymbol.data && searchedSymbol.data.status === "ok" && (
				<div className="right-1">
					<SlClose id="close-icon" className={checkModalClasses()} onClick={closeGraphModal} />
					<StockHeader tickerMeta={searchedSymbol.data.meta} key={searchedSymbol.data.meta.symbol} mode="parity" ticker={selectedTicker} />
					<div className="chart-container">
						<ValuesChart
							values={searchedSymbol.data.values}
							key={searchedSymbol.data.meta.symbol + 1}
							setTimeslot={setTimeslotSingleTickerGraph}
						/>
					</div>
				</div>
			)}
		</SurWrapper>
	);
};

export default Forex;

import React from "react";
import styled from "styled-components";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import { indexesPerCountry } from "../data";
import Select, { components } from "react-select";

const Wrapper = styled.article`
	/* border: 1px solid white; */
	font-size: 2rem;
	width: 100%;
	margin-top: 2rem;
	.title-container {
		font-size: 3rem;
		text-decoration: underline;
	}
	.search-container {
		/* border: 1px solid white; */
		width: 90%;
		margin: 1rem auto;
		.select-input {
			font-size: inherit;
			color: var(--font-5) !important;
			outline: none;
			border: none;
			width: 100%;
		}
	}
`;

const IndexSelection = ({ handleTickerSelection }) => {
	const DropdownIndicator = (props) => {
		return (
			<components.DropdownIndicator {...props}>
				<BiSolidDownArrow label="icon" color="#e45116f6" />
			</components.DropdownIndicator>
		);
	};

	return (
		<Wrapper>
			<div className="title-container">Main indexes</div>
			<div className="search-container">
				<Select
					onChange={handleTickerSelection}
					closeMenuOnSelect={true}
					components={{ DropdownIndicator }}
					options={indexesPerCountry["United States"]}
					className="select-input"
					styles={{
						option: (base) => ({
							...base,
							color: "#e45116f6",
							backgroundColor: null,
						}),
						singleValue: (base) => ({
							...base,
							color: "#e45116f6",
						}),
						placeholder: (base) => ({
							...base,
							color: "#e45116f6",
						}),
					}}
					// defaultValue={indexesPerCountry["United States"][0]}
				/>
			</div>
		</Wrapper>
	);
};

export default IndexSelection;

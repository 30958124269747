import React, { useState } from "react";
import styled from "styled-components";
import { CiSearch } from "react-icons/ci";

const Wrapper = styled.article`
	/* border: 1px solid white; */
	font-size: 2.5rem;
	width: 100%;
	margin-top: 2rem;
	.title-container {
		text-decoration: underline;
		font-size: 3rem;
	}
	.search-container {
		width: fit-content;
		margin: 1rem auto;
		display: flex;
		input {
			padding: 1rem;
			font-size: inherit;
			color: inherit;
			background-color: var(--blue-2);
			outline: none;
			border: none;
		}
		label {
			cursor: pointer;
			height: 49px;
			background-color: var(--font-5);
			color: var(--back-5);
			display: grid;
			place-content: center;
			font-size: 2.5rem;
			font-weight: bold;
		}
	}
`;

const SearchSelection = ({ autoCompletion }) => {
	return (
		<Wrapper>
			<div className="title-container">Search a stock</div>
			<div className="search-container">
				<input type="text" name="search" id="search" onChange={autoCompletion} />
				<label htmlFor="search">
					<CiSearch />
				</label>
			</div>
		</Wrapper>
	);
};

export default SearchSelection;

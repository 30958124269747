import React from "react";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import styled from "styled-components";

const Wrapper = styled.article`
	/* border: 1px solid white; */
	width: 90%;
	margin: 0 auto;
	padding: 0 1rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	.back-icon-container {
		align-self: flex-end;
		font-size: 4rem;
		cursor: pointer;
	}
`;

const ApiLimitsMessage = ({ message, setApiCallsLimitReachedMessage }) => {
	const handleCloseMessageBlock = () => {
		const middle1Elt = document.getElementById("middle-1");
		if (middle1Elt) {
			middle1Elt.classList.remove("hide");
		}
		setApiCallsLimitReachedMessage(null);
	};

	return (
		<Wrapper>
			<div>{message}</div>
			<div className="back-icon-container" onClick={handleCloseMessageBlock}>
				<IoArrowBackCircleSharp />
			</div>
		</Wrapper>
	);
};

export default ApiLimitsMessage;

import styled from "styled-components";

export const Wrapper = styled.section`
	/* border: 1px solid yellow; */
	margin: 0 1rem;
	font-size: 2rem;
	min-height: 90vh;
	height: fit-content;
	display: grid;
	grid-template-columns: 2fr 5fr 3fr;
	grid-template-rows: 25vh 20vh auto;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
	.left-1 {
		align-self: start;
		grid-column: 1/2;
		grid-row: 1/3;
		width: 100%;
		/* border: 1px solid white; */
	}
	.left-2 {
		align-self: start;
		grid-column: 1/2;
		grid-row: 2/3;
		width: 100%;
	}
	.left-3 {
		align-self: start;
		grid-column: 1/2;
		grid-row: 3/4;
		/* border: 1px solid white; */
		width: 100%;
	}
	.middle-1 {
		width: 100%;
		grid-column: 2/3;
		grid-row: 1/-1;
		align-self: start;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		margin-top: 2rem;
	}
	.right-1 {
		width: 100%;
		grid-column: 3/4;
		grid-row: 1/2;
		align-self: start;
	}
	.right-2 {
		/* border: 1px solid white; */
		grid-column: 3/4;
		grid-row: 2/3;
		width: 100%;
	}
	.right-3 {
		/* border: 1px solid white; */
		grid-column: 3/4;
		grid-row: 3/4;
		width: 100%;
	}
	.limit-error {
		margin-top: 5rem;
	}
	.close-icon {
		/* border: 1px solid white; */
		position: absolute;
		top: 3rem;
		right: 3rem;
		font-size: 4rem;
		opacity: 0;
		visibility: hidden;
	}
	.show {
		opacity: 1;
		visibility: visible;
	}
	.hide {
		opacity: 0;
		visibility: hidden;
	}
	.parities-limit-message {
		width: 90%;
		margin: 0 auto;
		padding: 0 1rem;
		display: flex;
		flex-direction: column;
		gap: 2rem;
		.back-icon-container {
			align-self: flex-end;
			font-size: 4rem;
			cursor: pointer;
		}
	}
	@media screen and (max-width: 1400px) {
		grid-template-columns: 25vw 1fr 30vw;
	}
	@media screen and (max-width: 1200px) {
		grid-template-columns: 1fr 3fr;
		.right-1 {
			position: absolute;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			background-color: var(--back-5);
		}
	}
	@media screen and (max-width: 600px) {
		display: flex;
		flex-direction: column;
		.close-icon {
			font-size: 3rem;
		}
	}
`;

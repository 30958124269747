import axios from "axios";
import React, { useEffect, useState } from "react";
import { getFlagSource } from "../data";
import { Wrapper } from "../css/MiddleColumnListingStyle";

const StocksDashboard = ({ handleTickerSelection, selectedStock }) => {
	const [someStocks, setSomeStocks] = useState();

	const minifyStocksArray = (array) => {
		return array.slice(0, 20);
	};

	const fetchSomeStocks = async () => {
		try {
			let targetUrl = process.env.REACT_APP_NODE_URL_BASE;
			const firstSegment = `stocks`;
			targetUrl += firstSegment;
			const res = await axios.get(targetUrl);
			const newRez = {
				data: minifyStocksArray(res.data.data.filter((obj) => obj.access.plan === "Basic")),
				count: minifyStocksArray(res.data.data.filter((obj) => obj.access.plan === "Basic")).length,
			};
			setSomeStocks(newRez);
		} catch (error) {}
	};

	useEffect(() => {
		fetchSomeStocks();
	}, []);

	// console.log(someStocks);

	if (!someStocks) return <>Loading...</>;

	return (
		<Wrapper>
			<div className="stock-line noHover stock-line-header">
				<span className="span-header">Country</span>
				<span className="span-header">Name</span>
				<span className="span-header">Currency</span>
				<span className="span-header">Echange</span>
				<span className="span-header">mic</span>
			</div>
			{someStocks &&
				someStocks.count > 0 &&
				someStocks?.data?.map((stock) => (
					<div className={selectedStock?.name === stock.name ? "stock-line active" : "stock-line"} onClick={() => handleTickerSelection(stock)}>
						{getFlagSource(stock.country) ? <img src={getFlagSource(stock.country)} alt="" className="flag" /> : <span>{stock.country}</span>}
						<span>{stock.name}</span>
						<span>{stock.currency}</span>
						<span>{stock.exchange}</span>
						<span>{stock.mic_code}</span>
					</div>
				))}
			<div className="dots-container">...</div>
		</Wrapper>
	);
};

export default StocksDashboard;

const regions = [
	{ label: "North America", value: "North America" },
	{ label: "South America", value: "South America" },
	{ label: "Europe", value: "europe" },
	// { label: "Middle East", value: "middleEast" },
	{ label: "Asia", value: "asia" },
];
const countriesPerRegion = {
	europe: ["France", "United Kingdom", "Italy", "Germany", "Spain", "Sweden", "Netherlands"],
	"North America": ["Canada", "United States"],
	"South America": ["Brazil", "Argentina"],
	asia: ["Japan", "South Korea", "China", "India", "Hong Kong", "Taiwan", "Russia"],
};

const marketsPerCountry = {
	"United States": ["NASDAQ", "NYSE", "OTC", "IEX"],
	China: ["SSE", "SZSE"],
	France: ["XPAR"],
	"South Korea": ["KRX", "KOSDAQ"],
	"Hong Kong": ["HKEX"],
	Taiwan: ["TWSE"],
	Germany: ["XSTU", "FSX", "EUREX", "XMUN", "XBER", "XDUS", "XETR", "XHAM", "XHAN"],
	"United Kingdom": ["LSE", "AIM", "LME", "NEX"],
	Canada: ["NEO", "TEX", "TSX", "CNSX", "XTSX"],
	Argentina: ["BCBA"],
	Brazil: ["BVMF"],
	Italy: ["XMIL", "MTA"],
	Spain: ["BME"],
	Japan: ["JPX", "XNGO", "XFKA", "XSAP"],
	India: ["BSE", "NSE"],
	Russia: ["MOEX"],
	Sweden: ["OMX", "XNGM", "XSAT"],
	Netherlands: ["EURONEXT"],
};

const indexesPerCountry = {
	"United States": [
		{ label: "S&P500", code: "SPX" },
		{ label: "NASDAQ COMPOSITE", code: "IXIC" },
		{ label: "NASDAQ 100", code: "NDX" },
		// { label: "NASDAQ", code: "XNGS" },
		{ label: "DOW JONES INDUSTRIAL", code: "DJI" },
	],
};

const micPerMarket = { NASDAQ: ["XNGS"], NYSE: ["XNYS"] };

const countryCodes = [
	{ name: "Afghanistan", code: "AF" },
	{ name: "Åland Islands", code: "AX" },
	{ name: "Albania", code: "AL" },
	{ name: "Algeria", code: "DZ" },
	{ name: "American Samoa", code: "AS" },
	{ name: "AndorrA", code: "AD" },
	{ name: "Angola", code: "AO" },
	{ name: "Anguilla", code: "AI" },
	{ name: "Antarctica", code: "AQ" },
	{ name: "Antigua and Barbuda", code: "AG" },
	{ name: "Argentina", code: "AR" },
	{ name: "Armenia", code: "AM" },
	{ name: "Aruba", code: "AW" },
	{ name: "Australia", code: "AU" },
	{ name: "Austria", code: "AT" },
	{ name: "Azerbaijan", code: "AZ" },
	{ name: "Bahamas", code: "BS" },
	{ name: "Bahrain", code: "BH" },
	{ name: "Bangladesh", code: "BD" },
	{ name: "Barbados", code: "BB" },
	{ name: "Belarus", code: "BY" },
	{ name: "Belgium", code: "BE" },
	{ name: "Belize", code: "BZ" },
	{ name: "Benin", code: "BJ" },
	{ name: "Bermuda", code: "BM" },
	{ name: "Bhutan", code: "BT" },
	{ name: "Bolivia", code: "BO" },
	{ name: "Bosnia and Herzegovina", code: "BA" },
	{ name: "Botswana", code: "BW" },
	{ name: "Bouvet Island", code: "BV" },
	{ name: "Brazil", code: "BR" },
	{ name: "British Indian Ocean Territory", code: "IO" },
	{ name: "Brunei Darussalam", code: "BN" },
	{ name: "Bulgaria", code: "BG" },
	{ name: "Burkina Faso", code: "BF" },
	{ name: "Burundi", code: "BI" },
	{ name: "Cambodia", code: "KH" },
	{ name: "Cameroon", code: "CM" },
	{ name: "Canada", code: "CA" },
	{ name: "Cape Verde", code: "CV" },
	{ name: "Cayman Islands", code: "KY" },
	{ name: "Central African Republic", code: "CF" },
	{ name: "Chad", code: "TD" },
	{ name: "Chile", code: "CL" },
	{ name: "China", code: "CN" },
	{ name: "Christmas Island", code: "CX" },
	{ name: "Cocos (Keeling) Islands", code: "CC" },
	{ name: "Colombia", code: "CO" },
	{ name: "Comoros", code: "KM" },
	{ name: "Congo", code: "CG" },
	{ name: "Congo, The Democratic Republic of the", code: "CD" },
	{ name: "Cook Islands", code: "CK" },
	{ name: "Costa Rica", code: "CR" },
	{ name: "Cote D'Ivoire", code: "CI" },
	{ name: "Croatia", code: "HR" },
	{ name: "Cuba", code: "CU" },
	{ name: "Cyprus", code: "CY" },
	{ name: "Czech Republic", code: "CZ" },
	{ name: "Denmark", code: "DK" },
	{ name: "Djibouti", code: "DJ" },
	{ name: "Dominica", code: "DM" },
	{ name: "Dominican Republic", code: "DO" },
	{ name: "Ecuador", code: "EC" },
	{ name: "Egypt", code: "EG" },
	{ name: "El Salvador", code: "SV" },
	{ name: "Equatorial Guinea", code: "GQ" },
	{ name: "Eritrea", code: "ER" },
	{ name: "Estonia", code: "EE" },
	{ name: "Ethiopia", code: "ET" },
	{ name: "Falkland Islands (Malvinas)", code: "FK" },
	{ name: "Faroe Islands", code: "FO" },
	{ name: "Fiji", code: "FJ" },
	{ name: "Finland", code: "FI" },
	{ name: "France", code: "FR" },
	{ name: "French Guiana", code: "GF" },
	{ name: "French Polynesia", code: "PF" },
	{ name: "French Southern Territories", code: "TF" },
	{ name: "Gabon", code: "GA" },
	{ name: "Gambia", code: "GM" },
	{ name: "Georgia", code: "GE" },
	{ name: "Germany", code: "DE" },
	{ name: "Ghana", code: "GH" },
	{ name: "Gibraltar", code: "GI" },
	{ name: "Greece", code: "GR" },
	{ name: "Greenland", code: "GL" },
	{ name: "Grenada", code: "GD" },
	{ name: "Guadeloupe", code: "GP" },
	{ name: "Guam", code: "GU" },
	{ name: "Guatemala", code: "GT" },
	{ name: "Guernsey", code: "GG" },
	{ name: "Guinea", code: "GN" },
	{ name: "Guinea-Bissau", code: "GW" },
	{ name: "Guyana", code: "GY" },
	{ name: "Haiti", code: "HT" },
	{ name: "Heard Island and Mcdonald Islands", code: "HM" },
	{ name: "Holy See (Vatican City State)", code: "VA" },
	{ name: "Honduras", code: "HN" },
	{ name: "Hong Kong", code: "HK" },
	{ name: "Hungary", code: "HU" },
	{ name: "Iceland", code: "IS" },
	{ name: "India", code: "IN" },
	{ name: "Indonesia", code: "ID" },
	{ name: "Iran, Islamic Republic Of", code: "IR" },
	{ name: "Iraq", code: "IQ" },
	{ name: "Ireland", code: "IE" },
	{ name: "Isle of Man", code: "IM" },
	{ name: "Israel", code: "IL" },
	{ name: "Italy", code: "IT" },
	{ name: "Jamaica", code: "JM" },
	{ name: "Japan", code: "JP" },
	{ name: "Jersey", code: "JE" },
	{ name: "Jordan", code: "JO" },
	{ name: "Kazakhstan", code: "KZ" },
	{ name: "Kenya", code: "KE" },
	{ name: "Kiribati", code: "KI" },
	{ name: "Korea, Democratic People'S Republic of", code: "KP" },
	{ name: "Korea, Republic of", code: "KR" },
	{ name: "Kuwait", code: "KW" },
	{ name: "Kyrgyzstan", code: "KG" },
	{ name: "Lao People'S Democratic Republic", code: "LA" },
	{ name: "Latvia", code: "LV" },
	{ name: "Lebanon", code: "LB" },
	{ name: "Lesotho", code: "LS" },
	{ name: "Liberia", code: "LR" },
	{ name: "Libyan Arab Jamahiriya", code: "LY" },
	{ name: "Liechtenstein", code: "LI" },
	{ name: "Lithuania", code: "LT" },
	{ name: "Luxembourg", code: "LU" },
	{ name: "Macao", code: "MO" },
	{ name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
	{ name: "Madagascar", code: "MG" },
	{ name: "Malawi", code: "MW" },
	{ name: "Malaysia", code: "MY" },
	{ name: "Maldives", code: "MV" },
	{ name: "Mali", code: "ML" },
	{ name: "Malta", code: "MT" },
	{ name: "Marshall Islands", code: "MH" },
	{ name: "Martinique", code: "MQ" },
	{ name: "Mauritania", code: "MR" },
	{ name: "Mauritius", code: "MU" },
	{ name: "Mayotte", code: "YT" },
	{ name: "Mexico", code: "MX" },
	{ name: "Micronesia, Federated States of", code: "FM" },
	{ name: "Moldova, Republic of", code: "MD" },
	{ name: "Monaco", code: "MC" },
	{ name: "Mongolia", code: "MN" },
	{ name: "Montserrat", code: "MS" },
	{ name: "Morocco", code: "MA" },
	{ name: "Mozambique", code: "MZ" },
	{ name: "Myanmar", code: "MM" },
	{ name: "Namibia", code: "NA" },
	{ name: "Nauru", code: "NR" },
	{ name: "Nepal", code: "NP" },
	{ name: "Netherlands", code: "NL" },
	{ name: "Netherlands Antilles", code: "AN" },
	{ name: "New Caledonia", code: "NC" },
	{ name: "New Zealand", code: "NZ" },
	{ name: "Nicaragua", code: "NI" },
	{ name: "Niger", code: "NE" },
	{ name: "Nigeria", code: "NG" },
	{ name: "Niue", code: "NU" },
	{ name: "Norfolk Island", code: "NF" },
	{ name: "Northern Mariana Islands", code: "MP" },
	{ name: "Norway", code: "NO" },
	{ name: "Oman", code: "OM" },
	{ name: "Pakistan", code: "PK" },
	{ name: "Palau", code: "PW" },
	{ name: "Palestinian Territory, Occupied", code: "PS" },
	{ name: "Panama", code: "PA" },
	{ name: "Papua New Guinea", code: "PG" },
	{ name: "Paraguay", code: "PY" },
	{ name: "Peru", code: "PE" },
	{ name: "Philippines", code: "PH" },
	{ name: "Pitcairn", code: "PN" },
	{ name: "Poland", code: "PL" },
	{ name: "Portugal", code: "PT" },
	{ name: "Puerto Rico", code: "PR" },
	{ name: "Qatar", code: "QA" },
	{ name: "Reunion", code: "RE" },
	{ name: "Romania", code: "RO" },
	{ name: "Russian Federation", code: "RU" },
	{ name: "Russia", code: "RU" },
	{ name: "RWANDA", code: "RW" },
	{ name: "Saint Helena", code: "SH" },
	{ name: "Saint Kitts and Nevis", code: "KN" },
	{ name: "Saint Lucia", code: "LC" },
	{ name: "Saint Pierre and Miquelon", code: "PM" },
	{ name: "Saint Vincent and the Grenadines", code: "VC" },
	{ name: "Samoa", code: "WS" },
	{ name: "San Marino", code: "SM" },
	{ name: "Sao Tome and Principe", code: "ST" },
	{ name: "Saudi Arabia", code: "SA" },
	{ name: "Senegal", code: "SN" },
	{ name: "Serbia and Montenegro", code: "CS" },
	{ name: "Seychelles", code: "SC" },
	{ name: "Sierra Leone", code: "SL" },
	{ name: "Singapore", code: "SG" },
	{ name: "Slovakia", code: "SK" },
	{ name: "Slovenia", code: "SI" },
	{ name: "Solomon Islands", code: "SB" },
	{ name: "Somalia", code: "SO" },
	{ name: "South Africa", code: "ZA" },
	{ name: "South Korea", code: "KR" },
	{ name: "South Georgia and the South Sandwich Islands", code: "GS" },
	{ name: "Spain", code: "ES" },
	{ name: "Sri Lanka", code: "LK" },
	{ name: "Sudan", code: "SD" },
	{ name: "Suriname", code: "SR" },
	{ name: "Svalbard and Jan Mayen", code: "SJ" },
	{ name: "Swaziland", code: "SZ" },
	{ name: "Sweden", code: "SE" },
	{ name: "Switzerland", code: "CH" },
	{ name: "Syrian Arab Republic", code: "SY" },
	{ name: "Taiwan", code: "TW" },
	{ name: "Tajikistan", code: "TJ" },
	{ name: "Tanzania, United Republic of", code: "TZ" },
	{ name: "Thailand", code: "TH" },
	{ name: "Timor-Leste", code: "TL" },
	{ name: "Togo", code: "TG" },
	{ name: "Tokelau", code: "TK" },
	{ name: "Tonga", code: "TO" },
	{ name: "Trinidad and Tobago", code: "TT" },
	{ name: "Tunisia", code: "TN" },
	{ name: "Turkey", code: "TR" },
	{ name: "Turkmenistan", code: "TM" },
	{ name: "Turks and Caicos Islands", code: "TC" },
	{ name: "Tuvalu", code: "TV" },
	{ name: "Uganda", code: "UG" },
	{ name: "Ukraine", code: "UA" },
	{ name: "United Arab Emirates", code: "AE" },
	{ name: "United Kingdom", code: "GB" },
	{ name: "United States", code: "US" },
	{ name: "United States Minor Outlying Islands", code: "UM" },
	{ name: "Uruguay", code: "UY" },
	{ name: "Uzbekistan", code: "UZ" },
	{ name: "Vanuatu", code: "VU" },
	{ name: "Venezuela", code: "VE" },
	{ name: "Viet Nam", code: "VN" },
	{ name: "Virgin Islands, British", code: "VG" },
	{ name: "Virgin Islands, U.S.", code: "VI" },
	{ name: "Wallis and Futuna", code: "WF" },
	{ name: "Western Sahara", code: "EH" },
	{ name: "Yemen", code: "YE" },
	{ name: "Zambia", code: "ZM" },
	{ name: "Zimbabwe", code: "ZW" },
];

const currencyCountryMapper = [
	{ name: "Australian Dollar", code: "AU" },
	{ name: "Japanese Yen", code: "JP" },
	{ name: "US Dollar", code: "US" },
	{ name: "New Zealand Dollar", code: "NZ" },
	{ name: "British Pound", code: "GB" },
	{ name: "Euro", code: "EU" },
	{ name: "Swiss Franc", code: "CH" },
	{ name: "Canadian Dollar", code: "CA" },
];

const currencyConverterMapper = [
	{
		label: "Afghanistan",
		currency_code: "AFN",
	},
	{
		label: "Albania",
		currency_code: "ALL",
	},
	{
		label: "Algeria",
		currency_code: "DZD",
	},
	{
		label: "American Samoa",
		currency_code: "USD",
	},
	{
		label: "Andorra",
		currency_code: "EUR",
	},
	{
		label: "Angola",
		currency_code: "AOA",
	},
	{
		label: "Anguilla",
		currency_code: "XCD",
	},
	{
		label: "Antarctica",
		currency_code: "XCD",
	},
	{
		label: "Antigua and Barbuda",
		currency_code: "XCD",
	},
	{
		label: "Argentina",
		currency_code: "ARS",
	},
	{
		label: "Armenia",
		currency_code: "AMD",
	},
	{
		label: "Aruba",
		currency_code: "AWG",
	},
	{
		label: "Australia",
		currency_code: "AUD",
	},
	{
		label: "Austria",
		currency_code: "EUR",
	},
	{
		label: "Azerbaijan",
		currency_code: "AZN",
	},
	{
		label: "Bahamas",
		currency_code: "BSD",
	},
	{
		label: "Bahrain",
		currency_code: "BHD",
	},
	{
		label: "Bangladesh",
		currency_code: "BDT",
	},
	{
		label: "Barbados",
		currency_code: "BBD",
	},
	{
		label: "Belarus",
		currency_code: "BYR",
	},
	{
		label: "Belgium",
		currency_code: "EUR",
	},
	{
		label: "Belize",
		currency_code: "BZD",
	},
	{
		label: "Benin",
		currency_code: "XOF",
	},
	{
		label: "Bermuda",
		currency_code: "BMD",
	},
	{
		label: "Bhutan",
		currency_code: "BTN",
	},
	{
		label: "Bolivia",
		currency_code: "BOB",
	},
	{
		label: "Bosnia and Herzegovina",
		currency_code: "BAM",
	},
	{
		label: "Botswana",
		currency_code: "BWP",
	},
	{
		label: "Bouvet Island",
		currency_code: "NOK",
	},
	{
		label: "Brazil",
		currency_code: "BRL",
	},
	{
		label: "British Indian Ocean Territory",
		currency_code: "USD",
	},
	{
		label: "Brunei",
		currency_code: "BND",
	},
	{
		label: "Bulgaria",
		currency_code: "BGN",
	},
	{
		label: "Burkina Faso",
		currency_code: "XOF",
	},
	{
		label: "Burundi",
		currency_code: "BIF",
	},
	{
		label: "Cambodia",
		currency_code: "KHR",
	},
	{
		label: "Cameroon",
		currency_code: "XAF",
	},
	{
		label: "Canada",
		currency_code: "CAD",
	},
	{
		label: "Cape Verde",
		currency_code: "CVE",
	},
	{
		label: "Cayman Islands",
		currency_code: "KYD",
	},
	{
		label: "Central African Republic",
		currency_code: "XAF",
	},
	{
		label: "Chad",
		currency_code: "XAF",
	},
	{
		label: "Chile",
		currency_code: "CLP",
	},
	{
		label: "China",
		currency_code: "CNY",
	},
	{
		label: "Christmas Island",
		currency_code: "AUD",
	},
	{
		label: "Cocos (Keeling) Islands",
		currency_code: "AUD",
	},
	{
		label: "Colombia",
		currency_code: "COP",
	},
	{
		label: "Comoros",
		currency_code: "KMF",
	},
	{
		label: "Congo",
		currency_code: "XAF",
	},
	{
		label: "Cook Islands",
		currency_code: "NZD",
	},
	{
		label: "Costa Rica",
		currency_code: "CRC",
	},
	{
		label: "Croatia",
		currency_code: "EUR",
	},
	{
		label: "Cuba",
		currency_code: "CUP",
	},
	{
		label: "Cyprus",
		currency_code: "EUR",
	},
	{
		label: "Czech Republic",
		currency_code: "CZK",
	},
	{
		label: "Denmark",
		currency_code: "DKK",
	},
	{
		label: "Djibouti",
		currency_code: "DJF",
	},
	{
		label: "Dominica",
		currency_code: "XCD",
	},
	{
		label: "Dominican Republic",
		currency_code: "DOP",
	},
	{
		label: "East Timor",
		currency_code: "USD",
	},
	{
		label: "Ecuador",
		currency_code: "ECS",
	},
	{
		label: "Egypt",
		currency_code: "EGP",
	},
	{
		label: "El Salvador",
		currency_code: "SVC",
	},
	{
		label: "England",
		currency_code: "GBP",
	},
	{
		label: "Equatorial Guinea",
		currency_code: "XAF",
	},
	{
		label: "Eritrea",
		currency_code: "ERN",
	},
	{
		label: "Estonia",
		currency_code: "EUR",
	},
	{
		label: "Ethiopia",
		currency_code: "ETB",
	},
	{
		label: "Falkland Islands",
		currency_code: "FKP",
	},
	{
		label: "Faroe Islands",
		currency_code: "DKK",
	},
	{
		label: "Fiji Islands",
		currency_code: "FJD",
	},
	{
		label: "Finland",
		currency_code: "EUR",
	},
	{
		label: "France",
		currency_code: "EUR",
	},
	{
		label: "French Guiana",
		currency_code: "EUR",
	},
	{
		label: "French Polynesia",
		currency_code: "XPF",
	},
	{
		label: "French Southern territories",
		currency_code: "EUR",
	},
	{
		label: "Gabon",
		currency_code: "XAF",
	},
	{
		label: "Gambia",
		currency_code: "GMD",
	},
	{
		label: "Georgia",
		currency_code: "GEL",
	},
	{
		label: "Germany",
		currency_code: "EUR",
	},
	{
		label: "Ghana",
		currency_code: "GHS",
	},
	{
		label: "Gibraltar",
		currency_code: "GIP",
	},
	{
		label: "Greece",
		currency_code: "EUR",
	},
	{
		label: "Greenland",
		currency_code: "DKK",
	},
	{
		label: "Grenada",
		currency_code: "XCD",
	},
	{
		label: "Guadeloupe",
		currency_code: "EUR",
	},
	{
		label: "Guam",
		currency_code: "USD",
	},
	{
		label: "Guatemala",
		currency_code: "QTQ",
	},
	{
		label: "Guinea",
		currency_code: "GNF",
	},
	{
		label: "Guinea-Bissau",
		currency_code: "CFA",
	},
	{
		label: "Guyana",
		currency_code: "GYD",
	},
	{
		label: "Haiti",
		currency_code: "HTG",
	},
	{
		label: "Heard Island and McDonald Islands",
		currency_code: "AUD",
	},
	{
		label: "Holy See (Vatican City State)",
		currency_code: "EUR",
	},
	{
		label: "Honduras",
		currency_code: "HNL",
	},
	{
		label: "Hong Kong",
		currency_code: "HKD",
	},
	{
		label: "Hungary",
		currency_code: "HUF",
	},
	{
		label: "Iceland",
		currency_code: "ISK",
	},
	{
		label: "India",
		currency_code: "INR",
	},
	{
		label: "Indonesia",
		currency_code: "IDR",
	},
	{
		label: "Iran",
		currency_code: "IRR",
	},
	{
		label: "Iraq",
		currency_code: "IQD",
	},
	{
		label: "Ireland",
		currency_code: "EUR",
	},
	{
		label: "Israel",
		currency_code: "ILS",
	},
	{
		label: "Italy",
		currency_code: "EUR",
	},
	{
		label: "Ivory Coast",
		currency_code: "XOF",
	},
	{
		label: "Jamaica",
		currency_code: "JMD",
	},
	{
		label: "Japan",
		currency_code: "JPY",
	},
	{
		label: "Jordan",
		currency_code: "JOD",
	},
	{
		label: "Kazakhstan",
		currency_code: "KZT",
	},
	{
		label: "Kenya",
		currency_code: "KES",
	},
	{
		label: "Kiribati",
		currency_code: "AUD",
	},
	{
		label: "Kuwait",
		currency_code: "KWD",
	},
	{
		label: "Kyrgyzstan",
		currency_code: "KGS",
	},
	{
		label: "Laos",
		currency_code: "LAK",
	},
	{
		label: "Latvia",
		currency_code: "EUR",
	},
	{
		label: "Lebanon",
		currency_code: "LBP",
	},
	{
		label: "Lesotho",
		currency_code: "LSL",
	},
	{
		label: "Liberia",
		currency_code: "LRD",
	},
	{
		label: "Libyan Arab Jamahiriya",
		currency_code: "LYD",
	},
	{
		label: "Liechtenstein",
		currency_code: "CHF",
	},
	{
		label: "Lithuania",
		currency_code: "EUR",
	},
	{
		label: "Luxembourg",
		currency_code: "EUR",
	},
	{
		label: "Macau",
		currency_code: "MOP",
	},
	{
		label: "North Macedonia",
		currency_code: "MKD",
	},
	{
		label: "Madagascar",
		currency_code: "MGF",
	},
	{
		label: "Malawi",
		currency_code: "MWK",
	},
	{
		label: "Malaysia",
		currency_code: "MYR",
	},
	{
		label: "Maldives",
		currency_code: "MVR",
	},
	{
		label: "Mali",
		currency_code: "XOF",
	},
	{
		label: "Malta",
		currency_code: "EUR",
	},
	{
		label: "Marshall Islands",
		currency_code: "USD",
	},
	{
		label: "Martinique",
		currency_code: "EUR",
	},
	{
		label: "Mauritania",
		currency_code: "MRO",
	},
	{
		label: "Mauritius",
		currency_code: "MUR",
	},
	{
		label: "Mayotte",
		currency_code: "EUR",
	},
	{
		label: "Mexico",
		currency_code: "MXN",
	},
	{
		label: "Micronesia, Federated States of",
		currency_code: "USD",
	},
	{
		label: "Moldova",
		currency_code: "MDL",
	},
	{
		label: "Monaco",
		currency_code: "EUR",
	},
	{
		label: "Mongolia",
		currency_code: "MNT",
	},
	{
		label: "Montserrat",
		currency_code: "XCD",
	},
	{
		label: "Morocco",
		currency_code: "MAD",
	},
	{
		label: "Mozambique",
		currency_code: "MZN",
	},
	{
		label: "Myanmar",
		currency_code: "MMR",
	},
	{
		label: "Namibia",
		currency_code: "NAD",
	},
	{
		label: "Nauru",
		currency_code: "AUD",
	},
	{
		label: "Nepal",
		currency_code: "NPR",
	},
	{
		label: "Netherlands",
		currency_code: "EUR",
	},
	{
		label: "Netherlands Antilles",
		currency_code: "ANG",
	},
	{
		label: "New Caledonia",
		currency_code: "XPF",
	},
	{
		label: "New Zealand",
		currency_code: "NZD",
	},
	{
		label: "Nicaragua",
		currency_code: "NIO",
	},
	{
		label: "Niger",
		currency_code: "XOF",
	},
	{
		label: "Nigeria",
		currency_code: "NGN",
	},
	{
		label: "Niue",
		currency_code: "NZD",
	},
	{
		label: "Norfolk Island",
		currency_code: "AUD",
	},
	{
		label: "North Korea",
		currency_code: "KPW",
	},
	{
		label: "Northern Ireland",
		currency_code: "GBP",
	},
	{
		label: "Northern Mariana Islands",
		currency_code: "USD",
	},
	{
		label: "Norway",
		currency_code: "NOK",
	},
	{
		label: "Oman",
		currency_code: "OMR",
	},
	{
		label: "Pakistan",
		currency_code: "PKR",
	},
	{
		label: "Palau",
		currency_code: "USD",
	},
	{
		label: "Palestine",
		currency_code: null,
	},
	{
		label: "Panama",
		currency_code: "PAB",
	},
	{
		label: "Papua New Guinea",
		currency_code: "PGK",
	},
	{
		label: "Paraguay",
		currency_code: "PYG",
	},
	{
		label: "Peru",
		currency_code: "PEN",
	},
	{
		label: "Philippines",
		currency_code: "PHP",
	},
	{
		label: "Pitcairn Islands",
		currency_code: "NZD",
	},
	{
		label: "Poland",
		currency_code: "PLN",
	},
	{
		label: "Portugal",
		currency_code: "EUR",
	},
	{
		label: "Puerto Rico",
		currency_code: "USD",
	},
	{
		label: "Qatar",
		currency_code: "QAR",
	},
	{
		label: "Reunion",
		currency_code: "EUR",
	},
	{
		label: "Romania",
		currency_code: "RON",
	},
	{
		label: "Russia",
		currency_code: "RUB",
	},
	{
		label: "Rwanda",
		currency_code: "RWF",
	},
	{
		label: "Saint Helena",
		currency_code: "SHP",
	},
	{
		label: "Saint Kitts and Nevis",
		currency_code: "XCD",
	},
	{
		label: "Saint Lucia",
		currency_code: "XCD",
	},
	{
		label: "Saint Pierre and Miquelon",
		currency_code: "EUR",
	},
	{
		label: "Saint Vincent and the Grenadines",
		currency_code: "XCD",
	},
	{
		label: "Samoa",
		currency_code: "WST",
	},
	{
		label: "San Marino",
		currency_code: "EUR",
	},
	{
		label: "Sao Tome and Principe",
		currency_code: "STD",
	},
	{
		label: "Saudi Arabia",
		currency_code: "SAR",
	},
	{
		label: "Scotland",
		currency_code: "GBP",
	},
	{
		label: "Senegal",
		currency_code: "XOF",
	},
	{
		label: "Serbia",
		currency_code: "RSD",
	},
	{
		label: "Seychelles",
		currency_code: "SCR",
	},
	{
		label: "Sierra Leone",
		currency_code: "SLL",
	},
	{
		label: "Singapore",
		currency_code: "SGD",
	},
	{
		label: "Slovakia",
		currency_code: "EUR",
	},
	{
		label: "Slovenia",
		currency_code: "EUR",
	},
	{
		label: "Solomon Islands",
		currency_code: "SBD",
	},
	{
		label: "Somalia",
		currency_code: "SOS",
	},
	{
		label: "South Africa",
		currency_code: "ZAR",
	},
	{
		label: "South Georgia and the South Sandwich Islands",
		currency_code: "GBP",
	},
	{
		label: "South Korea",
		currency_code: "KRW",
	},
	{
		label: "South Sudan",
		currency_code: "SSP",
	},
	{
		label: "Spain",
		currency_code: "EUR",
	},
	{
		label: "Sri Lanka",
		currency_code: "LKR",
	},
	{
		label: "Sudan",
		currency_code: "SDG",
	},
	{
		label: "Suriname",
		currency_code: "SRD",
	},
	{
		label: "Svalbard and Jan Mayen",
		currency_code: "NOK",
	},
	{
		label: "Swaziland",
		currency_code: "SZL",
	},
	{
		label: "Sweden",
		currency_code: "SEK",
	},
	{
		label: "Switzerland",
		currency_code: "CHF",
	},
	{
		label: "Syria",
		currency_code: "SYP",
	},
	{
		label: "Tajikistan",
		currency_code: "TJS",
	},
	{
		label: "Tanzania",
		currency_code: "TZS",
	},
	{
		label: "Thailand",
		currency_code: "THB",
	},
	{
		label: "The Democratic Republic of Congo",
		currency_code: "CDF",
	},
	{
		label: "Togo",
		currency_code: "XOF",
	},
	{
		label: "Tokelau",
		currency_code: "NZD",
	},
	{
		label: "Tonga",
		currency_code: "TOP",
	},
	{
		label: "Trinidad and Tobago",
		currency_code: "TTD",
	},
	{
		label: "Tunisia",
		currency_code: "TND",
	},
	{
		label: "Turkey",
		currency_code: "TRY",
	},
	{
		label: "Turkmenistan",
		currency_code: "TMT",
	},
	{
		label: "Turks and Caicos Islands",
		currency_code: "USD",
	},
	{
		label: "Tuvalu",
		currency_code: "AUD",
	},
	{
		label: "Uganda",
		currency_code: "UGX",
	},
	{
		label: "Ukraine",
		currency_code: "UAH",
	},
	{
		label: "United Arab Emirates",
		currency_code: "AED",
	},
	{
		label: "United Kingdom",
		currency_code: "GBP",
	},
	{
		label: "United States",
		currency_code: "USD",
	},
	{
		label: "United States Minor Outlying Islands",
		currency_code: "USD",
	},
	{
		label: "Uruguay",
		currency_code: "UYU",
	},
	{
		label: "Uzbekistan",
		currency_code: "UZS",
	},
	{
		label: "Vanuatu",
		currency_code: "VUV",
	},
	{
		label: "Venezuela",
		currency_code: "VEF",
	},
	{
		label: "Vietnam",
		currency_code: "VND",
	},
	{
		label: "Virgin Islands, British",
		currency_code: "USD",
	},
	{
		label: "Virgin Islands, U.S.",
		currency_code: "USD",
	},
	{
		label: "Wales",
		currency_code: "GBP",
	},
	{
		label: "Wallis and Futuna",
		currency_code: "XPF",
	},
	{
		label: "Western Sahara",
		currency_code: "MAD",
	},
	{
		label: "Yemen",
		currency_code: "YER",
	},
	{
		label: "Zambia",
		currency_code: "ZMW",
	},
	{
		label: "Zimbabwe",
		currency_code: "ZWD",
	},
];

const currencyConverterMapperTransformedLeft = currencyConverterMapper.map((obj) => ({ ...obj, label: `${obj.label}-${obj.currency_code}` }));
const currencyConverterMapperTransformedRight = currencyConverterMapper.map((obj) => ({ ...obj, label: `${obj.currency_code}-${obj.label}` }));

const serie1 = [
	[1147651200000, 67.79],
	[1147737600000, 64.98],
	[1147824000000, 65.26],
	[1147910400000, 63.18],
	[1147996800000, 64.51],
	[1148256000000, 63.38],
	[1148342400000, 63.15],
	[1148428800000, 63.34],
	[1148515200000, 64.33],
	[1148601600000, 63.55],
	[1148947200000, 61.22],
];

const serie2 = [
	[1152057600000, 57.0],
	[1152144000000, 55.77],
	[1152230400000, 55.4],
	[1152489600000, 55.0],
	[1152576000000, 55.65],
	[1152662400000, 52.96],
	[1152748800000, 52.25],
	[1152835200000, 50.67],
	[1153094400000, 52.37],
	[1153180800000, 52.9],
	[1153267200000, 54.1],
	[1153353600000, 60.5],
	[1153440000000, 60.72],
	[1153699200000, 61.42],
	[1153785600000, 61.93],
	[1153872000000, 63.87],
	[1153958400000, 63.4],
	[1154044800000, 65.59],
];

const getCountryCode = (string) => {
	//console.log("getCountryCode");
	//console.log(string);
	const flagObj = countryCodes.filter((obj) => obj.name === string);
	//console.log(flagObj);
	if (flagObj[0]?.code) return flagObj[0].code;
	else return "unknown";
};

const getCountryCodeFromCurrency = (string) => {
	//console.log("getCountryCode");
	//console.log(string);
	const flagObj = currencyCountryMapper.filter((obj) => obj.name === string);
	//console.log(flagObj);
	if (flagObj[0]?.code) return flagObj[0].code;
	else return "unknown";
};

//console.log(getCountryCode("Taiwan"));

const getFlagSource = (string) => {
	return `https://flagsapi.com/${getCountryCode(string)}/shiny/64.png`;
};

const getFlagSourceFromCurrency = (string) => {
	if (string === "Euro") {
		//console.log("Euro case");
		return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABR1BMVEVHcEwbQ4gDNZY0UnkAM5kAM5gHN5QAM5kAM5kAM5kTPo0DNJcIOJMPPI8MOpERPY4dRIfqvwxjbl3Orh0CNJffuRK6oikgRoVOYWp6fE/8ygG9pCdYaGPathVocVrEqCN3elFQY2j5yAMiR4TxwwgLOZJAWXJea2A4VHdsdFd8fU5WZmX9ywDYtBctTn3VsxivnC8JOJMfRYYeRYYNO5BpclnswAvTsRrwwwjYtRYpS4AvT3wXQYreuBPtwQoaQokZQollcFxlb1xNYWpMYWoBM5hrc1iBgEu3oCtDW3BCW3BibV5hbV4VP4wcQ4jpvwwENZZ+fk19fk2Zjj04VXeYjj34xwT3xwQ3VHcfRoUBNJiCgUq3oSpibl0VQIvkvA+QiUIzUXqNiEMqTH8pTH+Nh0RncFtmcFs/WXJtdFc+WHORikHlvA+2m0o9AAAACnRSTlMA////////n++V0ftFYQAAAXZJREFUWMPtlmdrwzAQhuNYcpPYbvbee++9u/fee+/2/3+uXQotVCZSDAUHv2AfB9KjO6PXkkajStWESD8lQ3oBoAMypFMAAEKZgEJhbIBWeNiNUmld+50QAijf1zsW2xKjjyIEwEtv8bwMQDSTiQLALRe9FkhWgTafqwrhDIBFIZhy+R3CFvhAIvWTHSQCPAnAhm7YhgtwutAAlxMLAOccVjfzdzrjtjpmIU4FjN0zRBUw47EzWC0Y0gs0CkDPLxkU4kZGejzWN4iHpAGh+GhAOGjeN6KnGw+3g+HRFaxEUlIFnEQuRlfAJitrUoDV0ySrjJ8qrKFH1yAmgLagARYaz43p68Edyo2Pg49XLDfCdgO5EYyNNsRzI216QAHuTbdEbpyWTPAAnfrvrN4hPRee++b3sri0uDZ3Y+4/EZ4LoNnqiSGbFd+9VpO0Bf6l2hWMsXd8tCuci93KGz/eVub8fk6WF6irTUrpV5z/AMi+aKpSNRH6BFofR9+bya3MAAAAAElFTkSuQmCC";
	} else return `https://flagsapi.com/${getCountryCodeFromCurrency(string)}/shiny/64.png`;
};

const timestampFormatter = (string) => {
	let date = new Date(string);
	let result = date.getTime();
	return result;
};

export {
	regions,
	countriesPerRegion,
	marketsPerCountry,
	micPerMarket,
	countryCodes,
	getCountryCode,
	getFlagSource,
	timestampFormatter,
	indexesPerCountry,
	currencyCountryMapper,
	getFlagSourceFromCurrency,
	serie1,
	serie2,
	currencyConverterMapper,
	currencyConverterMapperTransformedLeft,
	currencyConverterMapperTransformedRight,
};

import React from "react";
import styled from "styled-components";

const Wrapper = styled.section`
	min-height: 80vh;
	.text {
		margin: 10rem auto;
		width: 80vw;
	}
	@media screen and (max-width: 1000px) {
		font-size: 3rem;
	}
	@media screen and (max-width: 500px) {
		font-size: 2.5rem;
	}
`;

const Disclaimer = () => {
	return (
		<Wrapper>
			<p className="text">
				This website is made upon twelveData API free-tier plan. This implies limitations, like the rate of requests per minute. When limits are reached,
				some infos are displayed on the screen. It it occurs, be patient, and wait 1 or 2 minutes. Cheers.
			</p>
		</Wrapper>
	);
};

export default Disclaimer;

import axios from "axios";
import React from "react";
import styled from "styled-components";
import { regions, countriesPerRegion, marketsPerCountry } from "../data";
import { FaPlay } from "react-icons/fa";
import { IoPlayForwardOutline } from "react-icons/io5";

const Wrapper = styled.article`
	margin-top: 2rem;
	width: 100%;
	font-size: 2.5rem;
	.active {
		font-weight: bold;
		text-decoration: underline;
	}
	span {
		cursor: pointer;
		display: flex;
		align-items: center;
	}
	.title-container {
		font-size: 3rem;
		text-decoration: underline;
	}
	.grid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 1rem;
		.regions-container {
			margin: 1rem 0;
			display: flex;
			flex-direction: column;
			gap: 2rem;
		}
		.countries-container {
			margin: 1rem 0;
			display: flex;
			flex-direction: column;
			gap: 2rem;
		}
		.market-container {
			margin: 1rem 0;
			display: flex;
			flex-direction: column;
			gap: 2rem;
		}
	}
	/* @media screen and (max-width: 600px) {
		.title-container {
			font-size: 2rem;
		}
	} */
`;

const CountrySelection = ({ handleRegionSelection, handleCountrySelection, handleMarketSelection, selectedRegion, selectedCountry, selectedMarket }) => {
	return (
		<Wrapper>
			<div className="title-container">Regions/Countries</div>
			<div className="grid">
				<div className="regions-container">
					{regions.map((reg, index) => (
						<span key={index} onClick={handleRegionSelection} data-reg={reg.value} className={reg.value === selectedRegion ? `active` : null}>
							{reg.value === selectedRegion && (
								<span>
									<IoPlayForwardOutline />
								</span>
							)}
							&nbsp;
							{reg.label}
						</span>
					))}
				</div>
				<div className="countries-container">
					{countriesPerRegion[selectedRegion].map((country, index) => (
						<span key={index} onClick={handleCountrySelection} data-count={country} className={country === selectedCountry ? `active` : null}>
							{country === selectedCountry && (
								<span>
									<IoPlayForwardOutline />
								</span>
							)}
							&nbsp;
							{country}
						</span>
					))}
				</div>
				{selectedCountry && (
					<div className="market-container">
						{marketsPerCountry[selectedCountry].map((market) => (
							<span
								key={market}
								onClick={handleMarketSelection}
								data-markt={market}
								className={market === selectedMarket ? `active` : null}
							>
								{market === selectedMarket && (
									<span>
										<IoPlayForwardOutline />
									</span>
								)}
								&nbsp;
								{market}
							</span>
						))}
					</div>
				)}
			</div>
		</Wrapper>
	);
};

export default CountrySelection;

import styled from "styled-components";

export const Wrapper = styled.article`
	margin-bottom: 4rem;
	font-size: 2rem;
	.breadCrumbs {
		font-size: 3rem;
		text-transform: capitalize;
	}
	.search-column-title {
		font-size: 3rem;
		text-decoration: underline;
	}
	.stock-line {
		display: grid;
		grid-template-columns: 1fr 4fr 1fr 1fr 1fr;
		gap: 1rem;
		margin-top: 1rem;
		cursor: pointer;
		align-items: center;
		transition: all 0.4s ease-in-out;
		&:hover {
			font-weight: bold;
			/* font-size: 1.6rem; */
			background-color: var(--font-5);
			color: var(--back-5);
			/* margin: 1rem 0; */
			margin-left: 2rem;
		}
		.span-header {
			text-decoration: underline;
			font-weight: bold;
		}
		.span-spacer {
		}
		.flag {
			width: 4rem;
			height: 4rem;
		}
	}
	.stock-line-header {
		font-size: 2.5rem;
		margin-top: 0;
	}
	.noHover {
		&:hover {
			font-weight: normal !important;
			font-size: 2.5rem !important;
			background-color: var(--back-5) !important;
			color: var(--font-5) !important;
			margin: 0 !important;
		}
	}
	.active {
		font-weight: bold;
		font-size: 1.6rem;
		background-color: var(--font-5);
		color: var(--back-5);
		/* margin: 1rem 0; */
		margin-left: 2rem;
	}
	.pagination-container {
		margin: 2rem auto;
		display: flex;
		align-items: center;
		gap: 2rem;
		font-size: 3rem;
		width: fit-content;
		.btn-container {
			display: grid;
			place-content: center;
			width: 4rem;
			height: 4rem;
			background-color: var(--font-5);
			border: none;
			cursor: pointer;
			&:hover {
				background-color: var(--font-6);
			}
		}
	}
	.dots-container {
		margin: 2rem auto;
		font-size: 3rem;
		width: fit-content;
	}
	@media screen and (max-width: 800px) {
		font-size: 1.8rem;
	}
	@media screen and (max-width: 750px) {
		font-size: 1.8rem;
	}
	@media screen and (max-width: 600px) {
		font-size: 2rem;
	}
`;

import React, { useEffect, useState } from "react";
import { getFlagSource } from "../data";
import { Wrapper } from "../css/MiddleColumnListingStyle";
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from "react-icons/fi";

const IndexesDashboard = ({ indexes, handleTickerSelection, selectedIndex, page, maxPage, increasePage, decreasePage, goToMaxPage, goToMinPage }) => {
	const [localIndexesList, setLocalIndexesList] = useState();

	const getIndexesTrunk = (page) => {
		const n1 = page * 50;
		const n2 = (page + 1) * 50;
		return indexes.data.filter((stock) => stock.country).slice(n1, n2);
	};

	useEffect(() => {
		if (indexes) {
			setLocalIndexesList(getIndexesTrunk(page));
		}
	}, [page, indexes]);

	if (!indexes) return <>Loading...</>;

	return (
		<Wrapper>
			<div className="stock-line noHover">
				<span className="span-header">Country</span>
				<span className="span-header">Name</span>
				<span className="span-header">Currency</span>
				<span className="span-header">Echange</span>
				<span className="span-header">mic</span>
			</div>
			{localIndexesList?.map((index) => (
				<div className={selectedIndex === index.symbol ? "stock-line active" : "stock-line"} onClick={() => handleTickerSelection(index)}>
					{getFlagSource(index.country) ? <img src={getFlagSource(index.country)} alt="" className="flag" /> : <span>{index.country}</span>}
					<span>{index.name}</span>
					<span>{index.currency}</span>
					<span>{index.exchange}</span>
					<span>{index.mic_code}</span>
				</div>
			))}
			{maxPage > 1 && (
				<div className="pagination-container">
					<button className="btn-container" onClick={goToMinPage}>
						<FiChevronsLeft />
					</button>
					<button onClick={decreasePage} className="btn-container">
						<FiChevronLeft />
					</button>
					<p>
						{page + 1} / {maxPage}
					</p>
					<button onClick={increasePage} className="btn-container">
						<FiChevronRight />
					</button>
					<button className="btn-container" onClick={goToMaxPage}>
						<FiChevronsRight />
					</button>
				</div>
			)}
		</Wrapper>
	);
};

export default IndexesDashboard;

import React from "react";

import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Navbar from "./Navbar";

import styled from "styled-components";

const Wrapper = styled.div`
	width: 100%;
`;

const Layout = () => {
	return (
		<Wrapper>
			<Header />
			<Navbar />
			<Outlet />
			<Footer />
		</Wrapper>
	);
};

export default Layout;

import React from "react";
import { getFlagSourceFromCurrency } from "../data";
import { Wrapper } from "../css/MiddleColumnListingStyle";
import styled from "styled-components";

const SurWrapper = styled(Wrapper)`
	.stock-line {
		grid-template-columns: 2fr 3fr 1fr 1fr;
	}
`;

const DisplayForexPairsList = ({ selectedForexPairs, handleTickerSelection, selectedTicker }) => {
	return (
		<SurWrapper>
			<div className="stock-line noHover">
				<span className="span-header"></span>
				<span className="span-header">Currencies</span>
				<span className="span-header">Rate</span>
				<span className="span-header"></span>
			</div>

			{selectedForexPairs?.map((pair) => (
				<div className={selectedTicker === pair.symbol ? "stock-line active" : "stock-line"} onClick={() => handleTickerSelection(pair)}>
					<span>
						<img src={getFlagSourceFromCurrency(pair.currency_base)} alt="" className="flag" />
						<img src={getFlagSourceFromCurrency(pair.currency_quote)} alt="" className="flag" />
					</span>
					<span>{pair.symbol}</span>
					<span>1&nbsp;{pair.currency_base}&nbsp;for&nbsp;</span>
					<span>
						{parseFloat(pair.rate.rate).toFixed(2)}&nbsp;
						{pair.currency_quote}
					</span>
				</div>
			))}
		</SurWrapper>
	);
};

export default DisplayForexPairsList;

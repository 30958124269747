import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Select, { components } from "react-select";
import { BiSolidDownArrow } from "react-icons/bi";
import { countryCodes, currencyConverterMapper, currencyConverterMapperTransformedLeft, currencyConverterMapperTransformedRight } from "../data";
import { HiArrowsRightLeft } from "react-icons/hi2";
import axios from "axios";
import { currencynameToSymbolMapper } from "../data2";
import { availablePairs } from "../data3";

const Wrapper = styled.article`
	/* border: 1px solid white; */
	min-height: 20vh;
	display: grid;
	align-items: center;
	justify-content: center;
	grid-template-columns: 1fr 50px 1fr;
	.header {
		width: 100%;
		/* border: 1px solid white; */
		align-content: center;
		text-decoration: underline;
	}
	.align-right {
		text-align: end;
	}
	.input-container {
		/* border: 1px solid white; */
		width: 90%;
		margin: 1rem auto;
		.input {
			font-size: inherit;
			font-family: inherit;
			color: var(--font-5) !important;
			outline: none;
			border: none;
			width: 100%;
			height: 38px;
			border-radius: 4px;
		}
		.text-input {
			padding-left: 1rem;
		}
	}
	.arrows-container {
		/* border: 1px solid white; */
		display: grid;
		place-content: center;
	}
`;

const CurrencyConverter = () => {
	const [selectCurrencyLeft, setSelectCurrencyLeft] = useState("EUR");
	const [selectCurrencyRight, setSelectCurrencyRight] = useState("USD");

	const selectCurrencyLeftDefault = availablePairs?.find((obj) => obj.code === "EUR");
	const selectCurrencyRightDefault = availablePairs?.find((obj) => obj.code === "USD");

	const [selectedPairRate, setSelectedPairRate] = useState();

	const [inputValueLeft, setInputValueLeft] = useState();
	const [inputValueRight, setInputValueRight] = useState();

	const leftValueRef = useRef();
	const rightValueRef = useRef();

	const fetchParityRate = async (curr1, curr2) => {
		try {
			let targetUrl = process.env.REACT_APP_NODE_URL_BASE;
			const firstSegment = `exchange_rate`;
			const secondSegment = `symbol=${curr1}/${curr2}`;
			targetUrl += firstSegment + `?` + secondSegment;
			const res = await axios.get(targetUrl);
			const rate = res.data.rate;
			setSelectedPairRate(rate);
		} catch (error) {
			console.log(error);
		}
	};

	const DropdownIndicator = (props) => {
		return (
			<components.DropdownIndicator {...props}>
				<BiSolidDownArrow label="icon" color="#e45116f6" />
			</components.DropdownIndicator>
		);
	};

	const handleSelectLeft = (opt) => {
		leftValueRef.current.value = "";
		rightValueRef.current.value = "";
		setSelectCurrencyLeft(opt.code);
	};

	const handleSelectRight = (opt) => {
		leftValueRef.current.value = "";
		rightValueRef.current.value = "";
		setSelectCurrencyRight(opt.code);
	};

	const handleChangeLeftValue = (evt) => {
		setInputValueLeft(evt.target.value);
	};

	const handleChangeRightValue = (evt) => {
		setInputValueRight(evt.target.value);
	};

	useEffect(() => {
		fetchParityRate(selectCurrencyLeft, selectCurrencyRight);
	}, [selectCurrencyLeft, selectCurrencyRight]);

	useEffect(() => {
		if (inputValueLeft) {
			const newRightValue = inputValueLeft * selectedPairRate;
			rightValueRef.current.value = parseFloat(newRightValue).toFixed(2);
		}
	}, [inputValueLeft]);

	useEffect(() => {
		if (inputValueRight) {
			const newLeftValue = inputValueRight / selectedPairRate;
			leftValueRef.current.value = parseFloat(newLeftValue).toFixed(2);
		}
	}, [inputValueRight]);

	return (
		<Wrapper>
			<div className="header align-right">From</div>
			<div className="header"></div>
			<div className="header">To</div>
			<div className="align-right input-container">
				<Select
					closeMenuOnSelect={true}
					components={{ DropdownIndicator }}
					options={availablePairs}
					className="input"
					onChange={handleSelectLeft}
					styles={{
						option: (base) => ({
							...base,
							color: "#e45116f6",
							backgroundColor: null,
						}),
						singleValue: (base) => ({
							...base,
							color: "#e45116f6",
						}),
						placeholder: (base) => ({
							...base,
							color: "#e45116f6",
						}),
					}}
					defaultValue={selectCurrencyLeftDefault}
					key={selectCurrencyLeftDefault}
				/>
			</div>
			<div></div>
			<div className="input-container">
				<Select
					closeMenuOnSelect={true}
					components={{ DropdownIndicator }}
					options={availablePairs}
					className="input"
					onChange={handleSelectRight}
					styles={{
						option: (base) => ({
							...base,
							color: "#e45116f6",
							backgroundColor: null,
						}),
						singleValue: (base) => ({
							...base,
							color: "#e45116f6",
						}),
						placeholder: (base) => ({
							...base,
							color: "#e45116f6",
						}),
					}}
					defaultValue={selectCurrencyRightDefault}
					key={selectCurrencyRightDefault}
				/>
			</div>
			<div className="input-container">
				<input type="text" className="input text-input" onChange={handleChangeLeftValue} ref={leftValueRef} />
			</div>
			<div className="arrows-container">
				<HiArrowsRightLeft />
			</div>
			<div className="input-container">
				<input type="text" className="input text-input" onChange={handleChangeRightValue} ref={rightValueRef} />
			</div>
		</Wrapper>
	);
};

export default CurrencyConverter;
